p {
  font-size: 16px;
}

.jumbo {
  background-image: url("../../assets/images/bbbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 0;
  margin-bottom: 0;
}

.tinted {
  background-color: rgba(0, 0, 0, 0.7);
  padding-bottom: 20px;
}

.details {
  color: #000;
/* Body Copy */
font-size: 18px;
font-family: 'Libre Franklin', sans-serif;
font-weight: 300;
line-height: 26px;
letter-spacing: 0.036px;
}

h2.signup-title {
  color: var(--member-site-gold);
  font-size: 48px;
  font-style: normal;
  padding-left: 0;
  line-height: 0.85;
  margin-bottom: 32px;
}

.signup-button {
  background-color: var(--member-site-gold);
  transition: all 0.2s ease 0s;
  border-radius: 9px;
   height: 48px;
    text-align: center;
}

.signup-button-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-top: 12px;
}

.signup-button:hover {
    background-color: #99825D;
  }

.signup-header {
  color: #FFFFFF;
font-family: Playfair Display;
font-size: 48px;
font-weight: 500;
line-height: 54px;
letter-spacing: 0em;
position: relative;
}

.shadow {
  /* min-width: 10vw; */
  box-shadow: 8px 8px 26px 0px rgba(0, 0, 0, 0.06), -8px 4px 26px 0px rgba(0, 0, 0, 0.06);
}

.signup-form {
  padding-bottom: 30px;
}

@media (max-width: 576px) {
  .shadow {
    bottom: 9vw;
    margin-left: 10px;
    margin-right: 10px;
  }

  .signup-form {
    padding-top: 15px;
  }

  .signup-header {
    z-index: -1;
  }
}
