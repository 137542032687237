.inner-wrap[data-v-ecc8ce37] {
  background-color: white;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(134, 132, 128, 0.15);
}
.big-wrap[data-v-ecc8ce37] {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  background-color: #f6f5f2;
  overflow: hidden;
  height: 100%;
}
.little-wrap[data-v-ecc8ce37] {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  flex-basis: initial;
  height: 100%;
  overflow: auto;
}
@media (max-width: 445px) {
.little-wrap[data-v-ecc8ce37] {
    padding-left: 0px;
    padding-right: 0px;
}
}
.button[data-v-ecc8ce37] {
  height: 60px;
  margin: 15px 0;
  color: #ffffff;
  font-family: Oswald;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
}
.btn[data-v-ecc8ce37]:focus,
.btn[data-v-ecc8ce37]:active {
  box-shadow: none !important;
}
.lefty[data-v-ecc8ce37] {
  text-align: left;
  max-width: 230px;
  margin-right: 10px;
}
@media (max-width: 680px) {
.lefty[data-v-ecc8ce37] {
    width: 300px;
}
}
.title[data-v-ecc8ce37] {
  color: #242423;
  font-family: "Playfair Display";
  font-size: 32px;
  font-style: italic;
  letter-spacing: 0.75px;
  margin-bottom: 25px;
}
@media (max-width: 3415px) {
.title[data-v-ecc8ce37] {
    font-size: 28px;
}
}
h2.title[data-v-ecc8ce37] {
  padding-left: 0px;
  border-bottom: 0px;
  font-weight: 400;
  line-height: 1.5;
}
.title-smaller[data-v-ecc8ce37] {
  font-family: "Playfair Display";
  font-style: italic;
  letter-spacing: 0.75px;
  font-size: 18px;
  margin-bottom: 20px;
  text-decoration-line: underline;
  cursor: pointer;
  color: var(--carryout-site-gold);
}
.back-to-my-bag[data-v-ecc8ce37] {
  color: var(--link-color);
  font-family: var(--title-font-family);
  align-self: flex-start;
  margin: 0 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-height: 800px) {
.back-to-my-bag[data-v-ecc8ce37] {
    margin: 30px 3vh;
}
}
.back-to-my-bag-text[data-v-ecc8ce37] {
  color: var(--link-color);
  font-family: var(--title-font-family);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 3vh 0vh 3vh 1vh;
  text-decoration: underline;
  cursor: pointer;
}
.input-title[data-v-ecc8ce37] {
  color: black;
  font-family: Oswald;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.2px;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon-back[data-v-ecc8ce37] {
  height: 20px;
  width: 20px;
  margin: 1px 5px 0 0;
}
.olo-input[data-v-ecc8ce37] {
  box-sizing: border-box;
  height: 63px;
  border: 1px solid #868480;
  border-radius: 3px;
  background-color: #ffffff;
  width: 100%;
  margin: 12px 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: relative;
}
.horizontal-edges[data-v-ecc8ce37] {
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 20px;
}
input[data-v-ecc8ce37] {
  border: none;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  background-color: #ffffff;
}
input[data-v-ecc8ce37]::-webkit-credentials-auto-fill-button {
  position: absolute;
  right: 3px;
}
.divider[data-v-ecc8ce37] {
  height: 2px;
  width: calc(100% + 60px);
  margin-left: -30px;
  background-color: #dedbd6;
}
.pad[data-v-ecc8ce37] {
  padding: 0 44px;
}
.eye[data-v-ecc8ce37] {
  height: 12px;
  width: 16px;
  margin: 24px 25px;
}
.eye-wrap[data-v-ecc8ce37] {
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
.dont-have[data-v-ecc8ce37] {
  color: #242423;
  font-family: var(--title-font-family);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: var(--legacy-125-letter-spacing);
  line-height: 25px;
  cursor: pointer;
}
.checkout-as-guest[data-v-ecc8ce37] {
  color: var(--link-color);
  font-family: var(--title-font-family);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 25px;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}
.instruction-container[data-v-ecc8ce37] {
  color: #242423;
  font-family: Oswald !important;
  font-size: 16px !important;
  letter-spacing: 1.11px !important;
  line-height: 21px !important;
  max-width: 55%;
  align-self: flex-start;
  font-style: italic;
  font-weight: 200;
}
@media (max-width: 482px) {
.instruction-container[data-v-ecc8ce37] {
    max-width: 100%;
    margin-bottom: 20px;
}
.divider[data-v-ecc8ce37] {
    width: calc(100% + 30px);
    margin-left: -15px;
}
}
.align-top[data-v-ecc8ce37] {
  align-self: flex-start;
  margin-top: -3px;
}
.info-icon[data-v-ecc8ce37] {
  height: 18px;
  width: 18px;
  margin: 3px 0 0 10px;
}
