.modal-title {
  color: #242423;
  font-family: Oswald;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 1.75px;
}

.modal-title-dark {
  color: var(--carryout-site-gold) !important;
  font-family: Oswald;
  font-size: 23px;
  font-weight: 900;
  letter-spacing: 1.75px;
}
.modal-message{
  color: #242423;
  font-family: Oswald;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1.25px;
  margin-top: 30px;
}


.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  max-width: 45px;
  cursor: pointer;
  filter: drop-shadow(0 0 30px #000);
  z-index: 2;
}

@media (min-width: 320px) and (max-width: 480px) {
  .modal-overlay-inner {
    position: absolute;
    margin-left: 1%;
    width: 98%;
    background-color: white;
    height: 90vh;
    z-index: 999; 
    top:5vh;
   text-align: center;
   align-self: center;
   padding: 50px;
  }

}
@media (min-width: 481px) and (max-width: 900px) {
  .modal-overlay-inner {
    position: absolute;
    width: 85%;
    margin-left: 7.5%;
    background-color: white;
    height: 80vh;
    z-index: 999;
    top:10vh;
    text-align: center;
    align-self: center;
    padding: 50px;
  }
}

@media (min-width: 901px) and (max-width: 1280px) {
  .modal-overlay-inner {
    position: absolute;
    width: 60%;
    margin-left: 20%;
    background-color: white;
    height: 60vh;
    z-index: 999; 
    top:20vh;
    text-align: center;
    align-self: center;
    padding: 50px;
  }
}

@media (min-width: 1281px) {
  .modal-overlay-inner {
    position: absolute;
    margin-left: 32.5%;
    width: 35%;
    background-color: white;
    height: 40vh;
    z-index: 999; 
    top:30vh;
    text-align: center;
    align-self: center;
    padding: 50px;
    border: 1px solid #00000044;
  }
}