@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap");

h5 {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 26px;
}

.small-heading {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 19px;
  font-weight: 300;
}


.text {
  color: black;
  font-family: 'Playfair Display', sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 5px 0;
}

.f-container {
  background-color: white;
  position: relative;
  top: 17vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 4vw;
  box-shadow: 8px 8px 26px 0px rgba(0, 0, 0, 0.06), -8px 4px 26px 0px rgba(0, 0, 0, 0.06);
}

.form-items-container {
  padding-bottom: 68px;
}

.join-header {
  color: #000000;
  font-family: Playfair Display;
  font-size: 48px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: 0em;
  position: relative;
  right: 5vw;
  padding-top: 28px;
  padding-bottom: 32px;
}

.image-gradient {
  width: 100vw;
  height: 22.05vw;
  position: absolute;
  /* FF3.6+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.70) 0%, rgba(217, 217, 217, 0.00) 100%);
  /* W3C */
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.70) 0%, rgba(217, 217, 217, 0.00) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
}

.image-to-be-gradiented {
  width: 100vw;
  position: absolute;
}

h3 {
  color: #000000;
  font-family: "Playfair Display", serif;
  font-weight: bolder;
  color: #000;
  font-size: 28px;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
}

.text-libre {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.002em;
  text-align: left;
  color: #000;
}

h3.text {
  color: var(--wineclub-signup-gold);
  /* H3 */
  font-size: 28px;
  font-weight: 200;
  font-style: normal;
  font-family: 'Playfair Display', sans-serif;
  line-height: 37px;
}

.input-label {
  color: #171717;
  font-size: 18px;
  font-family: 'Libre Franklin', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.036px;
}

@media (max-width: 991px) {
  .join-header {
    position: relative;
  }
}

@media (max-width: 576px) {
  .f-container {
    top: 39vw;
    background-color: white;
    padding-left: 0px;
    padding-right: 0px;
  }

  .form-items-container {
    box-shadow: 8px 8px 26px 0px rgba(0, 0, 0, 0.06), -8px 4px 26px 0px rgba(0, 0, 0, 0.06);
  }

  h3.text {
    font-size: 22px;
  }

  .join-header {
    right: 0px;
    margin-left: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 22px;
  }

  .image-gradient {
    height: 56.2vw;
  }
}

.hrule {
  height: 1px;
  display: flex;
  flex-direction: row;
  background-color: var(--carryout-site-gold);
  margin: 1em 0 1.5em 0
}

.hruleSummary {
  height: 1px;
  display: flex;
  flex-direction: row;
  background-color: var(--carryout-site-gold);
  margin: 0.5em 0 1em 0
}

.toggleable {
  cursor: pointer;
}

.user-select {
  -webkit-user-select: none;
          user-select: none;
}

.underline {
  text-decoration: underline;
}

.tcs {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.claim-text {
  text-align: center;
  padding: 0 20px;
  font-family: 'Playfair Display';
  font-weight: 400;
}

.claim-col {
  background-color: #F7F5F3;
  height: 100%;
  width: 100%;
}

.claim-banner {
    height: 120px;
    background-color: white;
    filter: drop-shadow(0px 0px 8px #ccc);
    border: 1px solid #906F47;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.show-hide {
  text-align: center;
  text-decoration: underline;
  color: #000;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 19px;
  font-weight: 400;
}

.pointer:hover {
  cursor: pointer;
}

.club-card {
  height: 202px;
  width: 100px;
  margin: 15px;
}

.app-store {
  height: 50px;
  margin-top: 20px;
}

.error-banner {
  width:100%;
  padding: 15px 45px;
  background-color:#D74D4D;
  color: white;
  margin: 15px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 600;
  line-height: 26px;
}

.link {
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
}
 .hover {
  cursor: pointer;
 }