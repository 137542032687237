
  .tinted-container {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.6) 50%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
  
  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 85vh;
    background-color: #00000022;
  }
  
  .userSwitch {
    margin-top: 7em;
    margin-right: 4em;
    height: 15em;
    width: 10em;
    background-color: #000000dd;
    border-radius: 5px;
    border: 1px solid #ffffff66;
    padding: 1em;
    box-shadow: 0px 0px 50px #000;
    overflow: scroll;
    cursor: pointer;
  }