.jumbo {
  background-image: url("../../assets/images/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 0;
  margin-bottom: 0;
}

.vertical-shift {
   position: relative;
   top: 12vw;
  }

  .payment-join-header {
    color: #FFFFFF;
font-family: Playfair Display;
font-size: 48px;
font-weight: 500;
line-height: 54px;
letter-spacing: 0em;
position: relative;
 top: 7vw;
 left: 1.5vw;
}

@media (max-width: 1200px) {
  .vertical-shift {
    top: 10vw;
  }
  .payment-join-header {
    top: 6vw;
  }
}

@media (max-width: 992px) {
  .vertical-shift {
    top: 8vw;
  }
  .payment-join-header {
    top: 5vw;
  }
}

@media (max-width: 767px) {
  .vertical-shift {
    top: 6vw;
  }
  .payment-join-header {
    top: 4vw;
    right: 0px;
    margin-left: 10px;
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .vertical-shift {
    top: 18vw;
  }
  .payment-join-header {
    top: 20vw;
    font-size: 22px;
  }
}

.tinted-container {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 85vh;
  background-color: #00000022;
}

.userSwitch {
  margin-top: 7em;
  margin-right: 4em;
  height: 15em;
  width: 10em;
  background-color: #000000dd;
  border-radius: 5px;
  border: 1px solid #ffffff66;
  padding: 1em;
  box-shadow: 0px 0px 50px #000;
  overflow: scroll;
  cursor: pointer;
}