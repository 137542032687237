.multi-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top:0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
}


.multi-title {
  color: #242423;
  font-family: var(--title-font-family);
  font-size: 24px;
  font-weight: 700;
  letter-spacing: var(--legacy-175-letter-spacing);
  line-height: 32px;
  text-align: center;
}

.multi-message{
  color: #242423;
  font-family: Oswald;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1.25px;
  margin-top: 30px;
}


.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  max-width: 45px;
  cursor: pointer;
  filter: drop-shadow(0 0 10px #ccc);
  z-index: 2;
}

@media (max-width: 480px) {
  .multi-overlay-inner {
    position: absolute;
    width: 98%;
    background-color: white;
    height: 90vh;
   text-align: center;
   align-self: center;
   padding: 50px;
   overflow-y: auto;
  }

}
@media (min-width: 481px) and (max-width: 900px) {
  .multi-overlay-inner {
    position: absolute;
    width: 85%;
    margin-left: 7.5%;
    background-color: white;
    height: 80vh;
    z-index: 999;
    top:10vh;
    text-align: center;
    align-self: center;
    padding: 50px;
  }
}


@media (min-width: 901px) {
  .multi-overlay-inner {
    position: absolute;
    width: 35vw;
    background-color: white;
    z-index: 999; 
    text-align: center;
    padding: 50px;
    border: 1px solid #ccc;
    top: 20vh;
  }
}