@import url("https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap");
.select-container[data-v-eb9fc9d9] {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
          user-select: none;
  font-family: Oswald;
  padding: 0 15px;
  box-shadow: 0 2px 4px 0 rgba(134, 132, 128, 0.15);
}
.select-container-expanded[data-v-eb9fc9d9] {
  background-color: #fff;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 15px;
  font-family: "Teko", sans-serif;
  margin: 5px;
  -webkit-user-select: none;
          user-select: none;
}
.select-header-container[data-v-eb9fc9d9] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Teko", sans-serif;
  color: #000;
  font-size: 1.6rem;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.expanded-label[data-v-eb9fc9d9] {
  color: #aaa;
  font-style: italic;
  font-family: Oswald;
  font-size: 18px;
  letter-spacing: 1.25px;
}
.category[data-v-eb9fc9d9] {
  margin-right: 25px;
  font-family: "Teko", sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 1.4rem;
  color: #000;
}
.mobile-container[data-v-eb9fc9d9] {
  background-color: #f6f5f1;
  padding: 20px;
  text-align: center;
}
.selected-label[data-v-eb9fc9d9] {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.25px;
  padding: 15px 10px;
}
.select-arrow-down[data-v-eb9fc9d9] {
  height: 20px;
}
.select-arrow-up[data-v-eb9fc9d9] {
  height: 20px;
  transform: rotate(180deg);
}
.cat-list-item[data-v-eb9fc9d9] {
  display: inline-flex;
  padding: 10px;
  background-color: #dedbd6;
  border-radius: 4px;
  margin-bottom: 15px;
  margin-right: 15px;
  cursor: pointer;
  font-size: 1.4rem;
}
.cat-list-container[data-v-eb9fc9d9] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.cat-list-item[data-v-eb9fc9d9]:hover {
  background-color: #e8e5e0;
}
.selected[data-v-eb9fc9d9] {
  background-color: #aa885e;
  color: #fff;
}
.cat-list-text[data-v-eb9fc9d9] {
  line-height: 30px;
  height: 30px;
  font-family: Oswald;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1.4px;
  text-transform: capitalize;
}
.divider[data-v-eb9fc9d9] {
  height: 1px;
  width: 90%;
  background-color: #dedbd6;
  margin-bottom: 20px;
  margin-top: 20px;
}
.mobile-horiz-scroll-wrapper[data-v-eb9fc9d9] {
  height: 60px;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  overflow-x: scroll;
  align-items: center;
  margin: 2px 0;
  background-color: #f6f5f2;
  box-shadow: 0 2px 4px 0 rgba(134, 132, 128, 0.3);
  padding: 0 15px;
}
.submenu-item[data-v-eb9fc9d9] {
  font-family: var(--title-font-family);
  font-size: 14px;
  letter-spacing: 0.97px;
  text-align: center;
  padding: 0 10px;
  font-weight: 300;
  cursor: pointer;
  white-space: nowrap;
  text-transform: capitalize;
}
.sub-selected[data-v-eb9fc9d9] {
  color: var(--carryout-site-gold);
  text-decoration-line: underline;
  font-weight: 400;
}
@media (max-height: 820px) {
.cat-list-item[data-v-eb9fc9d9] {
    margin-right: 10px;
    margin-bottom: 10px;
}
.selected-label[data-v-eb9fc9d9] {
    padding: 10px;
}
.cat-list-text[data-v-eb9fc9d9] {
    font-size: 20px;
}
}
@media (max-height: 675px) {
.cat-list-item[data-v-eb9fc9d9] {
    margin-right: 10px;
    margin-bottom: 10px;
}
.cat-list-text[data-v-eb9fc9d9] {
    font-size: 18px;
    height: 20px;
    line-height: 20px;
}
}
@media (max-height: 600px) {
.divider[data-v-eb9fc9d9] {
    height: 1px;
    width: 90%;
    background-color: #dedbd6;
    margin-bottom: 10px;
    margin-top: 10px;
}
.select-container-expanded[data-v-eb9fc9d9] {
    padding: 10px;
}
.cat-list-item[data-v-eb9fc9d9] {
    margin-right: 7px;
    margin-bottom: 7px;
    padding: 5px 10px 5px 10px;
}
.cat-list-text[data-v-eb9fc9d9] {
    font-size: 15px;
    height: 17px;
    line-height: 18px;
}
.expanded-label[data-v-eb9fc9d9] {
    font-size: 16px;
}
}
.select-container-expanded[data-v-eb9fc9d9] {
  height: 100%;
}
.cat-list-container[data-v-eb9fc9d9] {
  overflow-y: auto;
  height: calc(100% - 50px);
}
@media (max-width: 425px) {
.select-header-container[data-v-eb9fc9d9] {
    margin-bottom: 10px;
}
.cat-list-item[data-v-eb9fc9d9] {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px;
}
.cat-list-text[data-v-eb9fc9d9] {
    font-size: 14px;
}
.divider[data-v-eb9fc9d9] {
    display: none;
}
}