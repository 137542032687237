@import url("https://fonts.googleapis.com/css?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Teko&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*Esquire Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,600;0,700;1,400;1,600&family=Josefin+Sans:ital,wght@0,400;0,700;1,400&display=swap');

:root{
  /* You need to define the default values for all variables here */
  --link-color: #906f47;
  --nav-tab-active: #906f47;
  --title-font-family: "Oswald";
  --item-name-color: #212529;
  --menu-item-background-color: #fff;
  --description-font-family: "Oswald";
  --header-background-color: #242423;
  --menu-item-banner-font: "Oswald", sans-serif;
  --header-cart-background-color: #906f47;
  --scroll-container-background-color: #f5f4f0;
  --menu-item-banner-background-color: #6f6c67;
  --category-font-family: "Playfair Display";
  --member-site-gold: #906f47;
  --carryout-site-gold: #906f47;
  --wineclub-signup-gold: #906f47;
  --button-dark-hover-color: #906f47cc;
}

.toast-container {
  background-color: black;
  width: 320px;

  color: white;
}

.form-control.is-valid,
.was-validated .form-control:valid,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: white;
}

.form-control[readonly] {
  background-color: #212529;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

html {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  height: 100%;
  width: 100%;
}

body {
  background: #000 url("./assets/images/bg.jpg") center center/cover no-repeat
    fixed;
  background-size: cover;
  border: none;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body::after{
   position: absolute; width:0; height:0; overflow:hidden; z-index:-1;
   content: url("./assets/images/bbbg.png") url("./assets/images/bbbg-v2.png") url("./assets/images/bbbg-v3.png");
}

body.barrel-reserve-bg {
  background: #000 url("./assets/images/bbg.jpg") center center/cover no-repeat
    fixed;
}

body.signup-bg {
  background: #000 url("./assets/images/bbbg.png") center center/cover no-repeat
    fixed;
}

body.gift-flow-bg {
  background: #fff
}

label {
  color: #fff;
}

#root {
  width: 100%;
  height: 100%;
}

p {
  font-size: 0.8rem;
  color: white;
}

a {
  color: #906f47;
  color: var(--link-color);
}

a:hover {
  color: #906f47;
  color: var(--link-color);
  text-decoration: underline;
}

a.nav-link,
a.nav-link:hover {
  color: #fff;
}

.navbar-dark {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)) !important;
}

.navbar-light {
  /* background: #FFFFFF */
}

.container-light {
  background: #FFFFFF;
  padding-right: 32px;
  padding-left: 32px;
}

.nav-tabs a.active {
  color: #f8c280 !important;
}

.nav-tabs a {
  color: #fff;
}

.bg-light {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

h4,
h2 {
  border-bottom: solid 1px #906f47;
  border-bottom: solid 1px var(--link-color);
  font-family: "Playfair Display", sans-serif;
  font-style: italic;
  color: #fff;
  padding-left: 10px;
}

svg.dashicon {
  width: 46px;
  height: 46px;
  margin: 0 auto;
  display: block;
}

svg.radial-chart {
  max-height: 17em;
}

g {
  fill: #ffffff;
}

circle.border {
  fill: none;
  stroke: #ffffff;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.logo-svg {
  fill: white;
  width: 240px;
  height: 94.82px;
  padding: 10px 5px;
}

.radial-chart {
  position: relative;
  display: block;
  transition: all 0.3s ease-in;
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
  height: 100%;
}

.radial-chart-progress {
  transform: rotate(270deg);
  transform-origin: center;
  transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
  transition-delay: 0.3s;
}

.borderless td,
th,
tr {
  color: #fff;
  border-color: #404040 !important;
  text-align: center !important;
  border-bottom: 2px;
  width: 150px;
  table-layout: fixed;
}

.btn-primary {
  background-color: #906f47 !important;
  background-color: var(--link-color) !important;
  border-color: #906f47 !important;
  border-color: var(--link-color) !important;
  transition: all 0.2s ease 0s;
}

.btn-primary:hover {
  background-color: #906f47cc !important;
  background-color: var(--button-dark-hover-color) !important;
  border-color: #906f47cc !important;
  border-color: var(--button-dark-hover-color) !important;
}

.tab-content {
  width: 100%;
  padding-top: 25px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 48px;
  margin-right: auto;
  margin-left: auto;
}

.form-control,
.form-control:focus {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #f8f9fa;
  background-color: #212529;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (max-width: 576px) {
  .container-light {
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media (min-width: 576px) {
  .tab-content {
    max-width: 540px;
  }
}

@media (min-width: 767px) {
  .tab-content {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .tab-content {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .tab-content {
    max-width: 1140px;
  }
}

@media (max-width: 767px) {
  .ch-tabs a {
    display: none;
  }
  .main {
    padding: 0px;
  }
}

.close,
.close:hover,
.close:active,
.close:focus {
  color: #fff;
  opacity: 1;
  outline: none !important;
}

.hide-toastify {
  display: none; 
}

.Toastify__toast-container--top-right {
  top: 5em !important;
  right: 0em !important;
}

.Toastify__toast--default {
  display: none !important;
}

.Toastify__toast--success {
  font-family: "Oswald" !important;
  font-family: var(--title-font-family) !important;
  font-weight: var(--legacy-toast-font-weight);
  letter-spacing: 1px;
  text-align: center;
  background: #f3dbbc !important;
  color: #212529;
  border: #906f47 solid 4px;
  border: var(--link-color) solid 4px;
}


.Toastify__toast--warning{
  font-family: "Oswald" !important;
  font-family: var(--title-font-family) !important;
  font-weight: var(--legacy-toast-font-weight);
  letter-spacing: 1px;
  text-align: center;
  background: #f3dbbc !important;
  color: #212529;
  border: #906f47 solid 4px;
  border: var(--link-color) solid 4px;
  pointer-events: initial;
}

.Toastify__toast-container--top-center{
  pointer-events: none;
}

@media(min-width: 768px){
  .handoff-warning {
    top: -3em !important;
  }
  .locator-warning {
    top: 3em !important;
  }
  .Toastify__toast--warning {
    top: 11em;
    left: 50%;
    width: 480px;
    margin-left: -240px;
  }
  .Toastify__toast-container--top-center {
    top: 3em !important;
  }
}

@media(max-width: 767px){
  .handoff-warning {
    top: -3em !important;
  }
  .locator-warning {
    top: -2em !important;
  }
  .Toastify__toast--warning {
    top: 3em;
  }
  .Toastify__toast-container--top-center {
    top: 4.5em !important;
  }
}

.Toastify__close-button {
  color: #212529 !important;
}

.empty {
  background-color: #ffffff22;
  color: white;
  height: 80px;
}