.ui-text {
  color: #000000;
  font-family: 'Libre Franklin', sans-serif;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1px;
}

.ui-text-enabled {
  color: #FFF;
text-align: center;
font-size: 16px;
font-family: "Libre Franklin", sans-serif;
font-style: normal;
font-weight: 600;
line-height: 24px;
letter-spacing: 1px;
text-transform: uppercase;
}

.next-text {
  color: #FFF;
text-align: center;
font-size: 16px;
font-family: 'Libre Franklin', sans-serif;
font-style: normal;
font-weight: 600;
line-height: 24px;
letter-spacing: 1px;
}

.ui-btn-enabled {
  border-radius: 9px;
  background-color: var(--member-site-gold);
  cursor: pointer;
}

.ui-btn-invalid {
  border-radius: 9px;
  opacity: 0.4;
  background-color: #eeeeee55;
}

.ui-btn-disabled{
  border-radius: 9px;
  cursor: pointer;
  border: 1px solid var(--member-site-gold);
}

.ui-btn-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 767px) {
  .ui-btn-wrap {
    height: 40px;
    margin-bottom: 8px;
  }
}

@media (min-width: 768px) {
  .ui-btn-wrap {
    height: 50px;
  }
}

.ui-btn-selected {
  border: 1px solid #000000e6;
  border-radius: 2px;
  top: 5px; right: 20px; bottom: 5px; left: 20px;
}
