.datetime-field-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
  cursor: pointer;
}
.datetime-field-container.paddingless {
  margin-bottom: 0;
}

.loading {
  opacity:0.3;
  pointer-events: none;
}

.loader {
  border: 2px solid #f3f3f3; 
  border-top: 2px solid #a47f51aa; 
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spacer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border: 2px solid #dedbd6;
  border-radius: 3px;
  padding: 15px;
  font-family: "Oswald";
  font-weight: 200;
  color: black;
  align-items: center;
  justify-content: center;
}
