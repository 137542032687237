.ambassador-score:nth-child(2) {
  opacity: 0;
}

.sub-list-item-text {
  margin-top: 0.4em;
  margin-left: 2em;
  color: white;
  font-size: 1.00rem;
  display: inline;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.list-item-text {
  margin-top: 0.4em;
  margin-left: 0.5em;
  color: white;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.coming-soon {
  margin-left: 2em;
}

.list-item {
  margin-left: 2em;
  margin-top: 0.5em;
}

@media (max-width: 1000px) {
  .list-item {
    width: 95%
  }
}

@media (min-width: 1001px) {
  .list-item {
    width: 45%
  }
}

.member-text {
  padding: 1em;
}

.dotContainer {
  margin-left: 2em;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #00000088;
  border: 1px solid white;
}

.member-title {
  font-size: 12px;
  padding: 0;
  margin-right: 5px;
  margin-bottom: 0;
}
.member-since {
  font-size: 12px;
  float: right;
  padding: 0;
  margin-right: 5px;
}
.member-selector {
  font-size: 12px;
  width: 9em;
  height: 1.5em;
  padding: 0;
}
.member-selector::-ms-expand {
  font-size: 12px;
  width: 9em;
}
.member-selector:hover {
  font-size: 12px;
  width: 9em;
}
.member-selector:focus {
  font-size: 12px;
  width: 9em;
}
.member-selector option {
  font-size: 12px;
  width: 9em;
}

#br-seal {
  width: 46px;
  height: 46px;
  margin: 0 auto;
  display: block;
}
