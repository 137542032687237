.bc-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  max-height: 100vh;
  z-index: 2;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
}


.bc-modal-title {
  color: #242423;
  font-family: Oswald;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 1.75px;
  line-height: 32px;
}

.bc-modal-available-bottles {
  color: #242423;
  font-family: Oswald;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1.75px;
  line-height: 32px;
}

.bc-modal-message{
  color: #242423;
  font-family: Oswald;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.25px;
  line-height: 25px;
  text-align: center;
  margin: 30px 0;
}


.bc-close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  max-width: 45px;
  cursor: pointer;
  filter: drop-shadow(0 0 3px #bbb);
  z-index: 2;
}

@media (min-width: 320px) and (max-width: 480px) {
  .bc-modal-overlay-inner {
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 999; 
    text-align: center;
    align-self: center;
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 80px;
    overflow: hidden;
    height: 100%;
  }
  .bc-overlay {
    overflow: hidden;
  }
  .content-container {
    overflow-y: auto;
    height: calc(100% - 5vh);
  }

  .dropdown-container .select-content {
    border: 1px solid #dedbd6;
  }
  .dropdown-container .select-content.open {
    top: auto;
    bottom: 46px;
    max-height: 180px;
    height: auto;
  }
}
@media (min-width: 481px) and (max-width: 900px) {
  .bc-modal-overlay-inner {
    position: absolute;
    width: 95%;
    background-color: white;
    z-index: 999;
    top:5vh;
    text-align: center;
    align-self: center;
    padding: 50px;
    height: 90%;
    max-height: 90vh;
  }
  .content-container {
    overflow-y: auto;
    height: calc(100% - 10vh);
  }
}

@media (min-width: 901px) and (max-width: 1280px) {
  .bc-modal-overlay-inner {
    position: absolute;
    width: 75%;
    background-color: white;
    z-index: 999; 
    top:15vh;
    text-align: center;
    align-self: center;
    padding: 50px;
    height: 85vh;
  }
  .content-container {
    overflow-y: auto;
    height: calc(100% - 15vh);
  }
}

@media (min-width: 1281px) {
  .bc-modal-overlay-inner {
    position: absolute;
    width: 55%;
    background-color: white;
    z-index: 999; 
    top:20vh;
    text-align: center;
    align-self: center;
    padding: 50px;
    border: 1px solid #00000044;
    height: 80vh;
  }
}
.content-container {
  overflow-y: auto;
  height: calc(100% - 10vh);
}