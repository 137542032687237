ul[data-v-4216598c] {
  list-style-type: none;
}
ul p[data-v-4216598c] {
  cursor: pointer;
}
.menu-wrap[data-v-4216598c] {
  background-color: #f5f4f0;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}
.big-wrap[data-v-4216598c] {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-width: 100vw;
  overflow: hidden;
  height: 100%;
}
.screen-reader-text[data-v-4216598c] {
  position: fixed;
  top: -200px;
}
.screen-reader-text[data-v-4216598c]:focus {
  top: 120px;
}
