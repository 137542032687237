.modal-content {
  color: white;
  background-color: black;
}

.nonfill-field {
  color: rgba(255,255,255,0.25);
}

h4.section-header {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  font-family: 'Playfair Display', sans-serif;
  color: var(--wineclub-signup-gold);
  padding: 0px;
}

/* This style is here because react bootstrap Forms are impossible to style properly.
A work around was done that shifted all items down a certain amount and this style is
here to undo that shift. */
.shift-up {
  position: relative;
  bottom: 38px;
}

.check-subtext {
  color: white;
  font-size: 80%;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.replacement-card {
  background: none !important;
  border-style: none;
  color: var(--member-site-gold);
  cursor: pointer;
  display: inline;
  font: inherit;
  padding: 0;
  padding-bottom: 25px;
  perspective-origin: 0 0;
  text-align: start;
  transform-origin: 0 0;

  -moz-appearance: none;
  -webkit-logical-height: 1em;
  -webkit-logical-width: auto;
}

.replacement-card:focus {
  color: var(--member-site-gold) !important;
  background: none !important;
  box-shadow: none !important;
}

.replacement-card:hover {
  color: var(--member-site-gold);
  background: none !important;
  text-decoration: underline;
}

@supports (-moz-appearance: none) {
  .replacement-card::-moz-focus-inner {
    background: none !important;
    border: none;
    padding: 0;
  }
  .replacement-card:focus {
    background: none !important;
  }
  .replacement-card:hover {
    background: none !important;
    text-decoration: underline;
  }
}

.inactive {
  background-color: #FFFFFF;
  padding: 0.5em;
  border: 1px solid #ffffff33;
  margin-left: 0.5px;
  margin-right: 0.5px;
}

.proceed-to-payment-button {
  background-color: var(--member-site-gold);
  transition: all 0.2s ease 0s;
  border-radius: 9px;
  text-align: center;
  display: flex;
width: 261px;
height: 54px;
padding: 15px 28px;
justify-content: center;
align-items: center;
flex-shrink: 0;
}

.proceed-to-payment-button:hover {
  background-color: #99825D;
}

.proceed-to-payment-text {
  color: #FFF;
text-align: center;
font-family: Libre Franklin, sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
letter-spacing: 1px;
}

.next-button {
   background-color: var(--member-site-gold);
  transition: all 0.2s ease 0s;
  border-radius: 9px;
  text-align: center;
  display: flex;
width: 98px;
margin-right: -1vw;
height: 54px;
padding: 15px 28px;
justify-content: center;
align-items: center;
flex-shrink: 0;
}



.next-button:hover {
  background-color: #99825D;
}

.next-text {
  color: #FFF;
text-align: center;
font-family: Libre Franklin, sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
letter-spacing: 1px;
}

.referral-header {
  width: 100%;
   /* height: 77px; */
   padding-top: 20px;
   padding-bottom: 20px;
    position: relative;
     bottom: 25px;
      background: var(--member-site-gold);
       border-radius: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
}

.referral-header-text {
  color: #FFF;
text-align: center;
font-family: Libre Franklin, sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
letter-spacing: 1px;
padding-left: 4vw;
padding-right: 4vw;
}

.signup-active {
  background-color: #FFFFFF;
  padding: 0.5em;
  border: 1px solid var(--member-site-gold);
}

.vert-spacer {
  height: 2em;
}

.total-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#giftReCaptchaContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

@media (min-width: 577px){
  #giftReCaptchaContainer {
    align-items: flex-end;
  }
}

@media (min-width: 379px){
  #giftReCaptchaInnerContainer {
    margin-right: 12px;
  }
}

@media (max-width: 378px){
  #giftReCaptchaContainer {
    width: max-content;
    margin-left: -10px;
  }

  #giftReCaptchaErrorMessage {
    margin-left: 10px;
  }
}

.gift-icon {
  align-self: right;
}

.btn-link {
  background-color: transparent !important;
  border: 0;
  box-shadow: none;
  color: var(--member-site-gold) !important;
  letter-spacing: 1px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}

.btn-link:hover,
.btn-link:active {
  background-color: transparent !important;
  border: 0;
  box-shadow: none;
  color: var(--member-site-gold);
}

.field {
  margin: 0.64em 0;
}

.form-optional{
  border-color: #000 !important;
  background-image: none !important;
}

.step-icon-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.step-summary-text {
  font-size: 13px;
  color: white;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
}

.summary-pricing {
  padding-top: 0.5em;
}

#info-icon-img {
  margin: -0.6em 0 0 0.32em;
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 576px) {
  h4.section-header {
    font-size: 22px;
  }

  .referral-header-text {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 767px) {
  .desktop-only {
    display: none !important;
  }
  
  .next-button {
    width: 100%;
    margin-right: 0px;
  }

  .little-icon-wrap {
    width: 69.5vw; 
    display: flex;
    justify-content: flex-start;
    margin: auto;
  }

  .summary-pricing {
    padding-top: 0.12em;
  }
}

@media (max-width: 768px) {
  .total-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}


@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }

  .little-icon-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-shift {
    position: relative;
    left: 40px;
  }
}

.form-control-select-custom-border {
  border: 1px solid black;
  border-radius: 10px;
  width: 100%;
  height: 38px;
}

.summary-bullet-container {
  box-sizing: border-box;
  width: 1rem;
  height: auto;
  padding-top: 0.28em;

}

.summary-bullet {
  background-color: var(--member-site-gold);
  height: 0.55rem;
  width: 0.55rem;
  border-radius: 50%;
}

.summary-text {
color: #000;
font-size: 18px;
font-family: 'Libre Franklin', sans-serif;
font-style: normal;
font-weight: 300;
line-height: 26px;
letter-spacing: 0.036px;
}

.summary-text-two {
color: #000;
font-size: 16px;
font-family: 'Libre Franklin', sans-serif;
font-style: normal;
font-weight: 300;
line-height: 26px;
letter-spacing: 0.036px;
}

.lin-grad {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.70) 0%, rgba(217, 217, 217, 0.00) 100%);
}

.membership-cost {
  display: flex;
  justify-content: flex-end;

}

.membership-cost h6 {
  color: #000;
/* Body Copy */
font-size: 18px;
font-family: 'Libre Franklin', sans-serif;
font-style: normal;
font-weight: 300;
letter-spacing: 0.036px;
margin-right: 40px;
}

.membership-cost h5 {
  font-family: "Playfair Display", serif;
  position: relative;
  bottom: 10px;

  color: #000;


/* H3 */
font-size: 28px;
font-family: Playfair Display;
font-style: normal;
font-weight: 400;
}


.delivery-option-text {
  color: rgba(225,225,225,0.54) !important;
}

.min-checkmark-pad{
  padding-right: 1.5em !important;
}

/* .collapsing {
  transition: height 0s ease;
}

.collapse.show {
  transition: height 0s ease;
} */

/* Removes margin for calendar icon in chrome in date fields */
::-webkit-calendar-picker-indicator{
  margin-left: 0px;
}

h2.log-title {
  font-size: 1.5rem;
  font-family: "Playfair Display", sans-serif;
    font-style: italic;
    color: #fff;
    padding: 0 0 0 10px;
}
