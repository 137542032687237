.bottle-status {
  color: #fff;
  display: inline;
  max-height: 46px;
  padding-right: 10px;
}

.text-center {
  padding-top: 20px;
}

.dine-in-bottles-text {
  color: white;
  font-size: 1.00rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 767px) {
  .text-xs-center {
    text-align: center;
  }
}

.preorder-header {
  font-weight: bold;
  font-size: 16px !important;
  color: white;
}

.preorder-card {
  border-top: 0.25px solid gray;
  border-top: 0.25px solid gray;
  padding-top: 5px;
  padding-bottom: 5px;
}

.preorder-details {
  color: white;
}

.visible-device {
  display: none;
}
.visible-desktop {
  display: block;
}

@media (max-width: 991px) {
  .visible-device {
    display: block;
  }
  .visible-desktop {
    display: none;
  }
}

td.like-th {
  font-weight: bold;
  text-align: center;
  width: 150px;
}

h2.log-title {
  font-size: 1.5rem;
  font-family: "Playfair Display", sans-serif;
    font-style: italic;
    color: #fff;
    padding: 0 0 0 10px;
}
