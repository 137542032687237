.ch-tabs a {
  color: white;
}

.ch-tabs > a.nav-item.nav-link.active {
  background-color: transparent;
  color: #f8c280 !important;
  border-bottom: 1px solid black;
  background-color: rgba(0, 0, 0, 0.9);
}

.ch-tabs > a:hover,
.ch-tabs > a:focus {
  border: 1px solid transparent;
  border-bottom: none;
  color: #fff;
  outline: none !important;
  box-shadow: none;
}

.admin-tab {
  color: #f8c280;
}

.ch-tabs > .nav-link {
  padding: 0;
}

.tab-link {
  display: table-cell;
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
}
.tab-link:hover {
  text-decoration: none;
}
