.toggle-wrap {
  position: absolute;
  height: 50px;
  background-color: white;
  bottom: 0;
  width: 100vw;
  filter: drop-shadow(5px 0 5px #ccc);
  z-index: 99;
}

.toggle-btn {
  border-radius: 0;
  font-family: Oswald, sans-serif;
  font-weight: 500;
  color: #d9d6d1;
  font-size: 1.1em;
  letter-spacing: 1px;
}

.toggle-btn-selected {
  font-family: Oswald, sans-serif;
  font-weight: 500;
  font-size: 1.1em;
  letter-spacing: 1px;
  color: var(--carryout-site-gold);
  border-radius: 0;
}

.selection-bar {
  background-color: var(--carryout-site-gold);
  height: 3px;
  width: 50%;
  position: absolute;
}

.btn-txt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
