.olo-input[data-v-5e0c5403] {
  box-sizing: border-box;
  height: 63px;
  border: 1px solid #868480;
  border-radius: 4px;
  background-color: #ffffff;
  margin: 10px 0 10px 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: relative;
}
@media (min-width: 768px){
.olo-input[data-v-5e0c5403] {
  margin: 10px 0 24px 0;
}
}
@media (max-width: 767px){
.olo-input[data-v-5e0c5403] {
  box-sizing: border-box;
  height: 63px;
  border: 1px solid #868480;
  border-radius: 4px;
  background-color: #ffffff;
  margin: 5px 0 5px 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: relative;
}
}
.smaller-container[data-v-5e0c5403] {
  margin: 4px 0;
  height: 50px;
}
.input[data-v-5e0c5403] {
  margin: 1px;
  border: none;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  background-color: #ffffff;
}
.smaller-input[data-v-5e0c5403] {
  padding: 0 8px;
}
.eye[data-v-5e0c5403] {
  height: 12px;
  width: 16px;
  margin: 23px 25px;
}
.smaller-eye[data-v-5e0c5403] {
  margin: 15px 25px;
}
.eye-wrap[data-v-5e0c5403] {
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (max-width: 480px) {
.eye-wrap[data-v-5e0c5403] {
    right: 15px;
}
}
.validation-message[data-v-5e0c5403] {
  color: #e13d3dbb;
  margin-top: -20px;
  left: 10px;
  font-size: 0.9rem;
}
.error[data-v-5e0c5403] {
  border-color: #e13d3dbb;
}
.component-wrap[data-v-5e0c5403] {
  position: relative;
}
.smaller-validation[data-v-5e0c5403] {
  margin-top: 0;
}
@media (max-width: 767px) {
.validation-message[data-v-5e0c5403] {
    margin-top: 0;
}
}
