.reCaptchaContainer {
  width: 303px;
  height: 77px;
  margin: auto;
}

.reCaptchaContainerError {
  border: solid red 1px;
  border-radius: 2px;
}

.reCaptchaErrorMessage {
  color: red;
  text-align: center;
}