@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap");
.complimentary-item-wrap {
  box-sizing: border-box;
  min-height: 60px;
  width: 100%;
  border: 1px solid rgba(134, 132, 128, 0.15);
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(222, 219, 214, 0.15);
  margin-top: 10px;
  margin-left: 0;
  padding: 0;
}

.complimentary-item-name {
  padding-left: 5px;
  color: #242423;
  font-family: Oswald;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 25px;
}

@media (max-width: 430px) {
  .complimentary-item-name {
    padding-left: 5px;
    letter-spacing: normal;
    letter-spacing: initial;
    font-size: 16px;
  }
}

.complimentary-item-note {
  padding-left: 5px;
  color: #212529;
  font-family: Oswald;
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 1.25px;
}

@media (max-width: 430px) {
  .complimentary-item-note {
    padding-left: 5px;
    letter-spacing: normal;
    letter-spacing: initial;
    font-size: 10px;
  }
}