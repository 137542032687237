@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap");

.upsell-item-wrap {
  box-sizing: border-box;
  min-height: 111px;
  width: 100%;
  border: 1px solid rgba(134, 132, 128, 0.15);
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(222, 219, 214, 0.15);
  margin-top: 10px;
  margin-left: 0;
  padding: 0;
}

.upsell-item-name {
  padding: 20px 0px 5px 5px;
  color: #242423;
  font-family: var(--title-font-family);
  font-size: 18px;
  font-weight: 900;
  letter-spacing: var(--legacy-125-letter-spacing);
  line-height: 25px;
}

.upsell-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.upsell-price {
  color: #242423;
  font-family: var(--title-font-family);
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 25px;
  text-align: right;
  position: absolute;
  bottom: 17px;
  right: 20px;
}

.upsell-description {
  font-family: var(--description-font-family);
  padding: 5px 5px 0px 5px;
  margin-bottom: 20px;
  font-size: 14px;
  letter-spacing: var(--legacy-125-letter-spacing);
  font-weight: 200;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-box-pack: end;
}

.plus-icon{
  height: 25px;
}

@media (max-width: 430px) {
  .upsell-item-name {
    padding: 10px 0px 5px 0px;
    letter-spacing: normal;
    letter-spacing: initial;
    font-size: 16px;
  }
  .upsell-description {
    padding: 5px 0px 00px 0px;
    margin-bottom: 10px;
  }
  .upsell-price {
    font-size: 16px;
    bottom: 15px;
    right: 15px;
  }
}
