.input-field[data-v-d41bb20a] {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  cursor: pointer;
  color: black;
}
.input-field.one-half[data-v-d41bb20a] {
  width: 50%;
}
.input-field.one-third[data-v-d41bb20a] {
  width: 33%;
}
.input-field input[data-v-d41bb20a] {
  border: 2px solid #dedbd6;
  border-radius: 3px;
  padding: 15px;
  font-family: "Oswald";
  font-weight: 200;
  color: black;
  cursor: pointer;
}
@media (max-width: 460px) {
.input-field input[data-v-d41bb20a] {
    padding: 10px;
    font-size: 15px;
    margin: 2px;
}
}
.select-content[data-v-d41bb20a] {
  position: absolute;
  top: 50px;
  max-height: 180px;
  overflow: auto;
  border-right: 2px solid #dedbd6;
  border-left: 2px solid #dedbd6;
  border-bottom: 2px solid #dedbd6;
  z-index: 99;
  display: none;
  left: 0;
  right: 0;
}
.select-content.open[data-v-d41bb20a] {
  display: block;
}
.select-content .item[data-v-d41bb20a] {
  background: white;
  padding: 10px 15px;
  border-bottom: 1px solid #dedbd6;
  font-family: Oswald;
  font-weight: 300;
  cursor: pointer;
}
.select-content .item.selected[data-v-d41bb20a] {
  color: black;
  font-weight: 400;
}
.select-content .item[data-v-d41bb20a]:hover {
  font-weight: 400;
}
.dropdown-cc[data-v-d41bb20a] {
  /* max-width: 10px; */
  margin-right: 5px;
  padding: 0px;
}
