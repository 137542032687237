.jumbo {
  background-image: url("../../assets/images/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 0;
  margin-bottom: 0;
}

.bg-light {
  margin-top: 6em;
  color: white;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

h1.log-title {
  border-bottom: solid 1px var(--carryout-site-gold);
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #ddd;
  padding: 0.32em 0.23em 0.23em 0.08em;
}
