.wrap[data-v-cd92887c] {
  display: flex;
  height: 80px;
  min-height: 80px;
  background-color: var(--header-background-color);
  justify-content: space-between;
  align-items: center;
}
.logo[data-v-cd92887c] {
  width: 240px;
  height: 80px;
}
.logo-small[data-v-cd92887c] {
  width: 180px;
  height: 60px;
}
.logo-xsmall[data-v-cd92887c] {
  width: 150px;
  height: 50px;
}
:not(.container-fluid) > .wrap[data-v-cd92887c] {
  width: 100%;
  margin: 0;
}
.sign-in[data-v-cd92887c] {
  font-family: var(--menu-item-banner-font);
  font-weight: 500;
  font-size: 1.1em;
  letter-spacing: 1px;
  width: 80px;
  color: var(--link-color);
  text-align: center;
  align-self: center;
  cursor: pointer;
  margin: 0px 2px;
}
.gold[data-v-cd92887c] {
  background-color: #ffffff22;
  color: white;
  height: 100%;
}
.bag-wrap[data-v-cd92887c] {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--header-cart-background-color);
}
@media (max-width: 480px) {
.wrap img[data-v-cd92887c] {
    margin-left: 5px;
}
.sign-in[data-v-cd92887c] {
    width: 70px;
    font-weight: 400;
}
}
@media (max-width: 375px) {
.sign-in[data-v-cd92887c] {
    width: auto;
    margin-right: 7px;
}
}
@media (max-height: 600px) {
.logo[data-v-cd92887c] {
    width: 150px;
    height: auto;
}
}
