.horiz {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 2;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
}

.overlay-title {
  font-family: var(--title-font-family);
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 44px;
}

.overlay-title-wine {
  font-family: var(--title-font-family);
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 1.5px;
  line-height: 44px;
}

.overlay-subtitle {
  font-family: var(--title-font-family);
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 25px;
}

.overlay-description {
  font-family: var(--description-font-family);
  font-size: 17px;
  font-weight: 200;
  letter-spacing: 1.25px;
  line-height: 25px;
  margin-bottom: 30px;
}

.overlay-description-wine {
  font-family: var(--description-font-family);
  font-size: 22px;
  font-weight: 200;
  letter-spacing: 1.5px;
  line-height: 30px;
  margin: 30px 0;
}

.required {
  color: var(--carryout-site-gold);
  font-family: Oswald;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 21px;
  margin-left: 1em;
  text-transform: none;
}

.product-img {
  height: auto;
  object-fit: contain;
  width: 100%;
  min-height: auto;
  pointer-events: none;
  background-color: #9d9d9d;
}

.inner-wrap {
  display: flex;
  padding: 20px 28px;
  flex-direction: column;
  background-color: var(--menu-item-background-color);
  color: var(--item-name-color);
}
 .add-container{
  background-color: rgba(255,255,255,0);
} 
.menu-overlay-inner{
  background-color: var(--menu-item-background-color);
}


.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  max-width: 45px;
  cursor: pointer;
  filter: drop-shadow(0 0 30px #000);
}

.caps {
  text-transform: uppercase;
}

.deets-scroll-container {
  overflow-y: auto;
  flex-direction: column;
  /* Firefox */
  /* WebKit */
  /* Standard */
  max-height: calc(100% - 120px);
}

.scroll-container.full-height {
  max-height: 100%;
}

.option-selected {
  height: 65px;
  width: 220px;
  display: flex;
  justify-content: center;
  border: 1px solid #dedbd6;
  border-radius: 3px;
  background-color: var(--carryout-site-gold);
  box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
  padding: 20px;
  margin: 10px 0;
  cursor: pointer;
  flex-direction: column;
}

.option {
  height: 65px;
  width: 220px;
  display: flex;
  justify-content: center;
  border: 1px solid #dedbd6;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
  padding: 20px;
  margin: 10px 0;
  cursor: pointer;
  flex-direction: column;
}

@media (min-width: 320px) and (max-width: 480px) {
  .menu-overlay-inner {
    position: relative;
    width: 95%;
    max-height: 90vh;
    margin-top: 5vh;
  }

  .menu-overlay-inner.handoff-edit {
    max-height: 75vh;
  }

  .option-selected {
    height: 65px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #dedbd6;
    border-radius: 3px;
    background-color: var(--carryout-site-gold);
    box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
    padding: 20px;
    margin: 10px 0;
    cursor: pointer;
    flex-direction: column;
  }

  .option {
    height: 65px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #dedbd6;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
    padding: 20px;
    margin: 10px 0;
    cursor: pointer;
    flex-direction: column;
  }
}
@media (min-width: 481px) and (max-width: 900px) {
  .menu-overlay-inner {
    position: relative;
    width: 70%;
    max-height: 90vh;
    margin-top: 5vh;
  }
  .menu-overlay-inner.handoff-edit-curbside {
    max-height: 70vh;
  }
  .menu-overlay-inner.handoff-edit {
    max-height: 75vh;
  }
  .menu-overlay-inner.handoff-edit-delivery {
    max-height: 90vh;
  }
  .option-selected {
    height: 65px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #dedbd6;
    border-radius: 3px;
    background-color: var(--carryout-site-gold);
    box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
    padding: 20px;
    margin: 10px 0;
    cursor: pointer;
    flex-direction: column;
  }

  .option {
    height: 65px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #dedbd6;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
    padding: 20px;
    margin: 10px 0;
    cursor: pointer;
    flex-direction: column;
  }
}

@media (min-width: 901px) and (max-width: 1280px) {
  .menu-overlay-inner {
    position: relative;
    width: 50%;
    max-height: 90vh;
    margin-top: 5vh;
  }
  .menu-overlay-inner {
    max-height: 70vh;
  }
  .menu-overlay-inner.handoff-edit {
    max-height: 75vh;
  }
  .menu-overlay-inner.handoff-edit-delivery {
    max-height: 90vh;
  }
}

@media (min-width: 1281px) {
  .menu-overlay-inner {
    position: relative;
    width: 35%;
    background-color: white;
    max-height: 90vh;
    margin-top: 5vh;
  }
  .menu-overlay-inner.handoff-edit-curbside {
    max-height: 70vh;
  }
  .menu-overlay-inner.handoff-edit {
    max-height: 75vh;
  }
  .menu-overlay-inner.handoff-edit-delivery {
    max-height: 90vh;
  }
}

.option-text {
  font-family: Oswald;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.11px;
  line-height: 21px;
}

.option-text-selected {
  color: white;
  font-family: Oswald;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.11px;
  line-height: 21px;
}

.option-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.divider {
  margin: 20px 0;
  height: 0.5px;
  width: 100%;
  background-color: #dedbd6;
}

.cost {
  font-family: Oswald;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.97px;
  line-height: 19px;
}

.cost-selected {
  color: white;
  font-family: Oswald;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.97px;
  line-height: 19px;
}

.request-field {
  height: 65px;
  width: 100%;
  border: 1px solid #dedbd6;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
  padding: 20px;
  margin: 10px 0;
  font-family: Oswald;
  font-size: 16px;
}

.handoff-select-container {
  margin-left: auto;
  margin-right: auto;
}

.delivery-restriction-container {
  margin: 10px -30px;
  height: 30px;
  background-color: lightgray;
  font-family: Oswald;
  font-weight: 200;
}
.delivery-restriction-container p {
  color: black;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.delivery-error-container p {
  padding: 0 15px;
  color: red;
  font-family: Oswald;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.input-field {
  display: flex;
  flex-direction: column;
  /* margin: 10px; */
  position: relative;
  width: 100%;
}
.input-field.one-half {
  width: 50%;
}
.input-field.one-third {
  width: 33%;
}
.input-field input {
  border: 2px solid #dedbd6;
  border-radius: 3px;
  padding: 15px;
  font-family: "Oswald";
  font-weight: 200;
  color: black;
}

.address-field-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
}
.address-field {
  position: relative;
}

.address-field button.clear-search {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  border: none;
  background-image: url("../../assets/images/icon-circle-close.png");
  background-size: cover;
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
}

.caret {
  position: absolute;
  display: inline-block;
  right: 10%;
  top: 50%;
  width: 0;
  height: 0;
}
.caret.caret-up {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--carryout-site-gold);
}
.caret.caret-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--carryout-site-gold);
}
.menu-overlay-inner.handoff-edit-curbside .overlay-title {
  text-align: center;
}
.menu-overlay-inner.handoff-edit .overlay-title {
  text-align: center;
}
.menu-overlay-inner.handoff-edit-delivery .overlay-title {
  text-align: center;
}
.menu-overlay-inner.handoff-edit-curbside .overlay-description {
  text-align: center;
  margin: 10px 0 40px 0;
}
.menu-overlay-inner.handoff-edit .overlay-description {
  text-align: center;
  margin: 10px 0 40px 0;
}
.menu-overlay-inner.handoff-edit-delivery .overlay-description {
  text-align: center;
  margin: 10px 0 40px 0;
}

.diner {
  height: 65px;
}

.suggested-pairing {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  border: 1px solid #dedbd6;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
  flex-wrap: wrap;
}

.pairing-img {
  height: 150px;
}

.pairing-txt-wrap {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}

.pairing-name {
  font-family: Oswald;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 20px;
  margin-bottom: 10px;
}

.pairing-description {
  font-family: Oswald;
  font-size: 1em;
  font-weight: 300;
  letter-spacing: 1.25px;
  line-height: 25px;
}

.add-to-order-btn {
  border: 1px solid var(--carryout-site-gold);
  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
}

.cancel-btn {
  background-color: transparent;
  color: var(--carryout-site-gold);
  margin-left: auto;
  margin-right: auto;
  border: none;
  font-family: Oswald;
  font-weight: 200;
  font-size: 20px;
  margin: 10px auto;
}

.add-to-order-txt {
  padding: 10px 20px;
  color: var(--carryout-site-gold);
  font-family: Oswald;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 25px;
  text-align: center;
}

.add-container {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 120px;
  width: 100%;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 -4px 4px 0 rgba(134, 132, 128, 0.15);
  padding: 0 30px;
}

.add-btn {
  height: 60px;
  border-radius: 3px;
  background-color: var(--carryout-site-gold);
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.save-btn {
  height: 60px;
  border-radius: 3px;
  background-color: var(--carryout-site-gold);
  text-transform: uppercase;
  color: white;
  border: none;
}

.add-btn-txt {
  color: #ffffff;
  font-family: Oswald;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  padding: 0px 8px;
}

@media (max-width: 420px) {
  .add-btn-txt {
    font-size: 16px;
  }
  
}

.menu-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 2;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
}

.overlay-title {
  font-family: var(--title-font-family);
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 44px;
}

.overlay-subtitle {
  font-family: var(--title-font-family);
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 25px;
}

.overlay-description {
  font-family: var(--description-font-family);
  font-size: 17px;
  font-weight: 200;
  letter-spacing: 1.25px;
  line-height: 25px;
  margin-bottom: 30px;
}

.required {
  color: var(--carryout-site-gold);
  font-family: Oswald;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 21px;
  margin-left: 1em;
  text-transform: none;
}

.product-no-img {
  width: 100%;
  min-height: 400px;
  pointer-events: none;
  background-color: #9d9d9d;
}

.inner-wrap {
  display: flex;
  padding: 20px 28px;
  flex-direction: column;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  max-width: 45px;
  cursor: pointer;
  filter: drop-shadow(0 0 30px #000);
}

.caps {
  text-transform: uppercase;
}

.deets-scroll-container {
  overflow-y: auto;
  flex-direction: column;
  /* Firefox */
  /* WebKit */
  /* Standard */
  max-height: calc(100% - 120px);
}

.scroll-container.full-height {
  max-height: 100%;
}

.option-selected {
  height: 65px;
  width: 240px;
  display: flex;
  justify-content: center;
  border: 1px solid #dedbd6;
  border-radius: 3px;
  background-color: var(--carryout-site-gold);
  box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
  padding: 20px;
  margin: 10px 0;
  cursor: pointer;
  flex-direction: column;
}

.option {
  height: 65px;
  width: 240px;
  display: flex;
  justify-content: center;
  border: 1px solid #dedbd6;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
  padding: 20px;
  margin: 10px 0;
  cursor: pointer;
  flex-direction: column;
}

@media (max-width: 480px) {
  .deets-scroll-container {
    overflow-y: auto;
    flex-direction: column;
    /* Firefox */
    /* WebKit */
    /* Standard */
    max-height: calc(100% - 100px);
  }

  .add-container {
    height: 100px;
    padding: 0 15px;
  }

  .menu-overlay-inner {
    position: relative;
    width: 95%;
    max-height: 90vh;
    margin-top: 5vh;
  }

  .overlay-title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1.25px;
    line-height: 36px;
  }

  .inner-wrap {
    padding: 15px;
  }

  .overlay-description {
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 1.25px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .menu-overlay-inner.handoff-edit {
    max-height: 75vh;
  }

  .option-selected {
    height: 65px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #dedbd6;
    border-radius: 3px;
    background-color: var(--carryout-site-gold);
    box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
    padding: 20px;
    margin: 10px 0;
    cursor: pointer;
    flex-direction: column;
    flex-basis: calc(50% - 5px);
  }

  .option {
    height: 65px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #dedbd6;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
    padding: 20px;
    margin: 10px 0;
    cursor: pointer;
    flex-direction: column;
    flex-basis: calc(50% - 5px);
  }
}
@media (min-width: 481px) and (max-width: 900px) {
  .menu-overlay-inner {
    position: relative;
    width: 70%;
    max-height: 90vh;
    margin-top: 5vh;
  }
  .menu-overlay-inner.handoff-edit-curbside {
    max-height: 70vh;
  }
  .menu-overlay-inner.handoff-edit {
    max-height: 75vh;
  }
  .menu-overlay-inner.handoff-edit-delivery {
    max-height: 90vh;
  }
  .option-selected {
    height: 65px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #dedbd6;
    border-radius: 3px;
    background-color: var(--carryout-site-gold);
    box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
    padding: 20px;
    margin: 10px 0;
    cursor: pointer;
    flex-direction: column;
  }

  .option {
    height: 65px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #dedbd6;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(123, 130, 150, 0.15);
    padding: 20px;
    margin: 10px 0;
    cursor: pointer;
    flex-direction: column;
  }
}

@media (min-width: 901px) and (max-width: 1280px) {
  .menu-overlay-inner {
    position: relative;
    width: 50%;
    max-height: 90vh;
    margin-top: 5vh;
  }
  .menu-overlay-inner {
    max-height: 70vh;
  }
  .menu-overlay-inner.handoff-edit {
    max-height: 75vh;
  }
  .menu-overlay-inner.handoff-edit-delivery {
    max-height: 90vh;
  }
}

@media (min-width: 1281px) {
  .menu-overlay-inner {
    position: relative;
    width: 35%;
    max-height: 90vh;
    margin-top: 5vh;
  }
  .menu-overlay-inner.handoff-edit-curbside {
    max-height: 70vh;
  }
  .menu-overlay-inner.handoff-edit {
    max-height: 75vh;
  }
  .menu-overlay-inner.handoff-edit-delivery {
    max-height: 90vh;
  }
}

@media (min-width: 600px) {
  .option {
    flex-basis: calc(50% - 5px);
  }
  .option-selected {
    flex-basis: calc(50% - 5px);
  }
}

.menu-overlay-inner .item-availability-description {
  font-family: Oswald, sans-serif;
  font-size: 15px;
  letter-spacing: 1.25px;
  font-weight: 200;
  margin-top: -20px;
  margin-bottom: 20px;
}

.join-message {
  border-top: 1px solid #ccc;
  padding-top: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}

.join-now { 
  cursor: pointer;
  color: #000;
  text-decoration: underline;
  font-weight: 600;
}