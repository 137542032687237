.big-wrap[data-v-26a280a2] {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  background-color: #f6f5f2;
  height: 100%;
}
.little-wrap[data-v-26a280a2] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-basis: initial;
  height: 100%;
  overflow: auto;
}
@media (max-width: 445px) {
.little-wrap[data-v-26a280a2] {
    padding-left: 0px;
    padding-right: 0px;
}
}
.back-to-my-bag[data-v-26a280a2] {
  color: var(--link-color);
  align-self: flex-start;
  margin: 0 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-height: 800px) {
.back-to-my-bag[data-v-26a280a2] {
    margin: 30px 3vh;
}
}
.back-to-my-bag-text[data-v-26a280a2] {
  color: var(--link-color);
  font-family: var(--title-font-family);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 3vh 0vh 3vh 1vh;
  text-decoration: underline;
  cursor: pointer;
}
.icon-back[data-v-26a280a2] {
  height: 20px;
  width: 20px;
  margin: 1px 5px 0 0;
}
.title[data-v-26a280a2] {
  color: black;
  font-size: 16px;
  font-family: Oswald;
  font-weight: 500;
}
