.jumbo {
  background-image: url("../../assets/images/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 0;
  margin-bottom: 0;
}

.bg-light {
  margin-top: 6em;
  color: white;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

h2.log-title {
  border-bottom: solid 1px var(--carryout-site-gold);
  color:#ddd;
  font-family: "Playfair Display", serif;
  font-style: italic;
  padding: 0.32em 0.23em 0.23em 0.08em;
}
h6.log-title {
  color:#ddd;
  margin-top: -0.25em;
  padding: 0 0 0.5em 0.05em;
}
.login-error {
  color: red;
  background-color: pink;
  font-size: 14px;
  padding: 10px;
}

p.signup-member-cta {
  text-decoration: none;
  padding: 0.67em 1em;
  border-radius: 1em;
  max-width: 55ch;
}
p.secondary-cta > a {
  color: #fff;
}
p.secondary-cta > a > .cta-highlight {
  color: var(--carryout-site-gold);
}
.signup-member-cta:hover {
  zoom: 1.008;
  text-decoration: none !important;
  border: none;
  transition: all ease 1400;
}
.signup-member-cta > span.cta-highlight {
  color: var(--carryout-site-gold);
}
@media(max-width: 550px){
  p.signup-member-cta {
    max-width: 35ch;
}

}/* end media query  */