@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap");

.search-container {
    background-color: white;
    border-radius: 5px;
    border: 1px solid black;
    padding: 5px 0;
    margin-bottom: 1em;
    position: relative;
    }

    @media (max-width: 460px) {
      .search-container {
        margin-bottom: 0px;
        }
    }

    .search-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-top: 1px solid #ddd;
      line-height: 40px;
      cursor: pointer;
      padding: 0 12px;
      width:414px;
    }

    .search-row:hover {
      background-color: #eee;
    }

    .search-result {
      color: black;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      height: 40px;
      font-size: small;
      margin: 0 5px;
    }

    .marker {
      margin-right: 5px;
      height: auto;
      width: 5px;
    }

    input {
        font-size:18px;
        font-family: 'Roboto', sans-serif;
    }

    input::-webkit-search-cancel-button{
        width:20px;
        height:20px;
        position:relative; 
        -webkit-appearance: none;
         background-image: url('../../assets/images/icon-circle-close.png');
         background-size:cover;
    }
   
    input:focus {
        outline: none;
      }
      ::placeholder {
        font-family: 'Roboto Condensed', sans-serif;
        font-style: italic;
        font-size: 18px;
      }

      .pac-container {
        margin-top: 6px;
        margin-left: -7px;
      }

      .pac-item-query {
        font-family: 'Roboto', sans-serif;
      }

      .pac-matched {
        font-family: 'Roboto', sans-serif;
        color: #a37c58;
      }

      .pac-icon {
          color:  #a37c58;
      }