#left {
  position: absolute;
}

#right {
  position: absolute;
  bottom: 0;
  right: 0;
}

#monthly-summary-text {
  max-width: 67%;
  line-height: 1.5;
}

#monthly-membership-cost {
  transform: translate3d(0, 5.5em, 0);
  text-align: right;
}

@media screen and (max-width: 795px) {
  #monthly-membership-cost {
    transform: translate3d(0, 0, 0);
    text-align: right;
  }
}

.content {
  display: flex;
  flex-direction: row;
  max-width: 800px;
}

.wrap {
  display: flex;
  justify-content: center;
}

.summary {
  background-color: #eeeeee55;
  padding: 10px;
}

.inner {
  position: relative;
  height: 180px;
  display: flex;
  align-items: center;
  border: 1px solid #a67f58;
}

#gift-dynamic-bottle {
  padding: 1em;
  width: 3.5em;
  min-width: 4.6em;
  height: auto;
}

svg {
  width: 150px;
  height: 180px;
}

line {
  stroke: #a67f58;
  stroke-width: 1;
}

.membership-summary {
  display: flex;
  margin-top: -12px;
}

@media (min-width: 480px){
  .bottle {
    max-height: 160px;
    align-self: center;
    margin-right: 0.5em;
    margin-left: 2em;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .content {
  min-width: 270px;
  }
  .bottle {
    max-height: 100px;
    margin-right: 15px;
    margin-left: 25px;
  }
  .summary-text {
    font-size: 10px;
    color: white;
    font-family: "Oswald", sans-serif;
  }
}


.big-icon-wrap {
  display: flex;
  flex-direction: row;
}

.summary-little-icon-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  max-height: 18px;
  margin-right: 10px;
}

.summary-block-text {
  color: #000000;
  margin-right: 20px;
  letter-spacing: 1px;


font-size: 18px;
font-family: "Libre Franklin", sans-serif;
font-style: normal;
font-weight: 300;
letter-spacing: 0.036px;
}

.summary-block-info-icon {
  padding: 0px;
  height: 16px;
  width: 16px;
}

.bottom-horizontal-wrap {
  display: flex;
}

.ok {
  color: #000000;
  position: relative;
  top: 42px;
}

.ok-disabled {
    color: #000000;

text-align: right;
}

.ok h6 {
  color: #000;
text-align: right;
/* Body Copy */
font-size: 18px;
font-family: 'Libre Franklin', sans-serif;
font-style: normal;
font-weight: 300;
line-height: 26px;
letter-spacing: 0.036px;
}

.ok h5 {
  font-family: "Playfair Display", serif;
  font-weight: bolder;

  color: #000;
text-align: right;

/* H3 */
font-size: 28px;
font-family: Playfair Display;
font-style: normal;
font-weight: 400;
line-height: 37px;
}

.empty-summary {
  font-style: italic;
  font-weight: lighter;
  font-size: 0.8em;
  padding: 0 6em 0 4em;
  align-self: center;
  color: #cccccc88;
}

@media (max-width: 991px) and (min-width: 766px) {
  .content {
    height: 225px;
  }
  .inner {
    height: 100%;
  }
  svg {
    height: 225px;
    width: 200px;
  }
  .bottom-horizontal-wrap {
    height: 130px;
  }
}

@media (min-width: 768px) {
  .bottom-horizontal-wrap {
    min-width: 92%;
  }
  
  .content {
    min-width: 100%;
    line-height: 1.2;
  }

  .summary-block-text {
    max-width: 80%;
  }
  
  #summary-club-text {
    max-width: 67%;
    transform: translate3d(0.1em,0.18em,0);
  }
  
  
  .summary-block-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

#membership-cost {
  transform: translate3d(-1.2em,-1.2em,0);
}

#summary-big-icon-wrap {
  /* padding-top: 70px; */
  /* padding-left: 80px; */
}

@media (max-width: 767px) {
  .content {
    width: 67vw;
    height: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
  } 
  #summary-club-text {
   text-align: left;
  }
  .inner {
    height: 100%;
  }
  svg {
    display: none !important;
  }
  .bottle {
    display: none !important;
  }
  .summary {
    padding: 4px;
  }
  .membership-summary {
    flex-direction: column;
  }
  .summary-block-text {
    font-size: 0.8em;
    margin: 0 5px 0 3px;
  }
  .summary-block-price {
    margin-top: 5px;
  }
  .icon {
    margin-right: 3px;
  }
  .summary-little-icon-wrap {
    margin: 15px 0 20px 10px;
  }
  #summary-big-icon-wrap {
   margin-left: 5px;
  }
  .big-icon-wrap {
    display: flex;
    flex-direction: column;
  }
  .form-label {
    padding-top: 2px;
  }
  .bottom-horizontal-wrap {
    display: block;
    margin-top: 0.5em;
    height: 100%;
  }
  .ok {
    text-align: left;
    margin-top: 20px;
    top: 0px;
  }
  .ok h5 {
    text-align: left;
  }
  .ok h6 {
    text-align: left;
  }
  #membership-cost {
    transform: translate3d(-0.2em,0,0);
  }
}
