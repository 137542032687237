.included-txt {
  color: #ffffff;
  font-family: Oswald;
  font-size: 17px;
  font-weight: 200;
  letter-spacing: 1.25px;
  line-height: 25px;
  text-align: right;
  font-style: italic;
}

.sub-totals {
  /* height: 125px; */
  padding: 5px 25px;
}

@media (max-width: 430px) {
  .sub-totals {
    /* height: 60px; */
    padding: 20px;
  }
}

.info-row {
  display: flex;
  flex-direction: row;
  margin: 6px 5px;
  justify-content: space-between;
  height: 25px;
}

.info-item {
  color: #242423;
  font-family: var(--title-font-family);
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 25px;
}

.clear-cart {
  color: #464646;
  font-family: var(--title-font-family);
  font-size: 12px;
  letter-spacing: var(--legacy-125-letter-spacing);
  margin-right: 40px;
  margin-bottom: 10px;
  cursor: pointer;
}

.conversion-item {
  height: 106px;
  border-radius: 3px;
  background-color: #6f6c67;
  margin: 30px;
  padding: 10px;
}

.converted-name {
  font-family: Oswald;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 25px;
  display: flex;
  flex-direction: 50pxrow;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 20px;
  color: white;
}

.use-bottles {
  height: 50px;
  display: flex;
  flex: 1 1;
  border-radius: 3px;
  background-color: #6f6c67;
  width: 100%;
  /* color: #ffffff;
  font-family: Oswald;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1.11px; */
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.use-bottles-text {
  /* height: 50px;
  display: flex;
  flex: 1;
  border-radius: 3px;
  background-color: #6f6c67;
  width: 100%; */
  color: #ffffff;
  font-family: Oswald;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1.11px;
  margin: 0px 10px;
  /* align-items: center;
  cursor: pointer; */
}

@media (max-width: 480px) {
  .use-bottles {
    padding: 0 10px 0 0;
  }
}
@media (max-width: 510px) {
  .use-bottles {
    font-size: 14px;
  }
  .little-bottle {
    margin-right: 15px !important;
  }
  .little-circle-arrow {
    margin-right: 10px !important;
  }
  .use-bottles-text {
    font-size: 14px;
  }
}

@media (max-width: 370px) {
  .use-bottles-text {
    font-size: 12px;
  }
}

.little-bottle {
  height: 20px;
  width: 10px;
  margin: 15px;
}

.little-circle-arrow {
  height: 20px;
  width: 20px;
  margin: 15px;
  margin-left: auto;
}

.little-close-icon {
  height: 28px;
  width: 28px;
  margin: 15px;
  margin-left: auto;
}

.location-detail-icon {
  height: 1em;
  width: 1em;
  vertical-align: inherit;
  margin-left: 5px;
  cursor: pointer;
}

.member-number {
  color: #ffffff;
  font-family: Oswald;
  font-size: 17px;
  font-weight: 200;
  letter-spacing: 0.89px;
  margin-bottom: 10px;
}

.member-text-message {
  color: #ffffff;
  font-family: Oswald;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.89px;
  margin-bottom: 20px;
}

.removing {
  padding-left: 30%;
  position: relative;
  padding-bottom: 40px;
}

.light {
  opacity: 0.7;
}

.cart-edit {
  color: #6f6c67;
  text-decoration: underline;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 1.11px;
  line-height: 21px;
  cursor: pointer;
  margin-right: 16px;
}

.item-price {
  color: #242423;
  font-family: var(--title-font-family);
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1.25px;
  text-align: right;
}

.cart-remove {
  color: #6f6c67;
  text-decoration: underline;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 1.11px;
  line-height: 21px;
  cursor: pointer;
}

.edit-remove-row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  font-family: var(--title-font-family);
}

.cart-item-wrap {
  margin: 10px 20px;
  margin-right: 60px;
  justify-content: space-around;
}

.conversion {
  background-color: turquoise;
}

.cart-item-options {
  font-family: Oswald, sans-serif;
  font-weight: 300;
  margin-bottom: 5px;
  letter-spacing: 1px;
  line-height: 1.5em;
  color: #242423;
}

.cart-title {
  color: #242423;
  font-family: var(--category-font-family);
  font-size: 30px;
  font-style: italic;
  letter-spacing: 1.16px;
  margin: 10px 20px 5px 30px;
}

h2.cart-title {
  padding-left: 0px;
  border-bottom: 0px;
  font-weight: 400;
  line-height: 1.5;
}

@media (max-width: 530px) {
  .cart-title {
    font-size: 28px;
    margin-top: 50px;
  }
  .cart-item-wrap {
    margin: 20px;
    justify-content: space-around;
  }
}
@media (max-width: 410px) {
  .cart-title {
    font-size: 25px;
  }
}

.cart-item-title {
  font-family: var(--menu-item-banner-font);
  font-weight: 900;
  font-size: 17px;
  margin-bottom: 5px;
  color: #242423;
  padding: 0;
}

.remove-cart-item-button {
  background-color: var(--cart-remove-button-color);
}

.cart-location-title {
  max-width: 85%;
  color: #242423;
  font-family: var(--title-font-family);
  font-size: 17px;
  font-weight: 900;
  margin-bottom: 10px;
  margin-left: 30px;
  letter-spacing: var(--header-letter-spacing);
  text-transform: uppercase;
  position: relative;
}

.location-edit {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0 0 0 2px;
}
.location-edit-icon {
  height: 100%;
  margin-bottom: 8px;
}

.cart-address-line {
  font-family: var(--cart-address-line-font-family);
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 30px;
  letter-spacing: var(--header-letter-spacing);
  line-height: 1.4em;
}

.cart-dispatch-method {
  color: var(--link-color);
  font-family: var(--title-font-family);
  font-size: 17px;
  font-weight: 500;
  letter-spacing: var(--legacy-125-letter-spacing);
  line-height: 1.5em;
  margin-top: 10px;
  margin-left: 30px;
  cursor: pointer;
  text-decoration: underline;
}

.cart-divider {
  height: 10px;
  width: 100%;
  background-color: #f6f5f2;
  margin: 10px 0;
}

.cart-divider2 {
  background-color: #dedbd6;
  height: 2px;
  width: 100%;
  margin: 10px 0;
}

.cart-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}



.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  max-width: 45px;
  cursor: pointer;
  filter: drop-shadow(0 0 10px #aaa);
  z-index: 2;
}

@media (min-width: 320px) and (max-width: 480px) {
  .cart-overlay-inner {
    position: relative;
    width: 98%;
    background-color: white;
    height: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
  }
}
@media (min-width: 481px) and (max-width: 900px) {
  .cart-overlay-inner {
    position: relative;
    width: 85%;
    background-color: white;
    height: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
  }
}

@media (min-width: 901px) and (max-width: 1280px) {
  .cart-overlay-inner {
    position: relative;
    width: 60%;
    background-color: white;
    height: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
  }
}

@media (min-width: 1281px) {
  .cart-overlay-inner {
    position: relative;
    width: 35%;
    background-color: white;
    height: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
  }
}

.cart-overlay-inner {
  transform: translateX(0%) translateZ(0px);
  position: absolute;
  top: 0;
  bottom: 0;
  /* background-color: aqua; */
}

.complete-meal-wrap {
  background-color: #f6f5f2;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  margin: 0;
}

.complete-meal-title {
  color: #242423;
  font-family: var(--title-font-family);
  font-size: 17px;
  font-weight: 900;
  letter-spacing: var(--legacy-125-letter-spacing);
  line-height: 25px;
}
.bottle-club {
  width: 100%;
  background-color: #6f6c67;
  box-shadow: 0 0 4px 0 rgba(222, 219, 214, 0.15);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottle-club-join {
  color: #ffffff;
  font-family: Oswald;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.85px;
}

.member {
  margin-bottom: 10px;
}

.bold {
  font-weight: bold;
}

.learn-more {
  height: 25px;
  width: 87px;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.84px;
  line-height: 25px;
  text-decoration: underline;
  cursor: pointer;
}

.wine-club-box {
  background-color: #6f6c67;
  padding: 20px;
}

.wine-club-info {
  color: #ffffff;
  font-family: var(--title-font-family);
  font-size: 18px;
}

.already-a-member {
  color: #ffffff;
  font-family: var(--description-font-family);
  font-weight: 300;
  font-size: 17px;
  letter-spacing: var(--legacy-084-letter-spacing);
  line-height: 25px;
  font-weight: 100;
  font-style: var(--title-font-style);
  margin-top: 20px;
}

.fees-wrap {
  margin: 30px;
}

.line-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10px;
  color: #242423;
  font-family: Oswald;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1.25px;
  padding: 6px 0;
}

.cart-empty {
  pointer-events: none;
  background-color: #6f6c67 !important
}

.checkout-btn {
  height: 80px;
  border-radius: 3px;
  background-color: var(--link-color);
  color: #ffffff;
  font-family: Oswald;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1.67px;
  line-height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px;
  cursor: pointer;
}

@media (max-width: 425px) {
  .checkout-btn {
    height: 50px;
    margin: 20px;
  }
}

.cart-scroll-container {
  overflow-y: auto;
  flex-direction: column;
  height: 100%;
  padding-bottom: 9em;
  background-color: white;
}

.button-floater {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background-color: transparent;
  box-shadow: 3px 3px 5px 6px #ccc;
  border: 1px solid transparent;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: #ffffff88;
}

.checkout-extras .form-check-label {
  font-family: Oswald, sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: #242423;
  -webkit-user-select: none;
          user-select: none;
}

.checkout-extras .form-check-input {
  margin-top: auto;
}

.checkout-extras .form-check {
  display: flex;
  align-items: center;
}

.complimentary-row {
  margin: 10px 5px;
  white-space: nowrap;
}

.complimentary-row .col {
  margin-bottom: 15px;
}

.basket-loading-opacity {
  opacity: .6;
  background-color: #eee;
}

@media (max-height: 820px) {
  .cart-scroll-container {
    padding-bottom: 9em;
  }
  .cart-location-title button.location-edit {
    position: absolute;
    right: 0;
  }
}
