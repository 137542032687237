.ambassador-score:nth-child(2) {
    opacity: 0;
  }

h2.log-title {
  font-size: 1.5rem;
  font-family: "Playfair Display", sans-serif;
    font-style: italic;
    color: #fff;
    padding: 0 0 0 10px;
}

td.like-th {
  font-weight: bold;
  text-align: center;
  width: 150px;
}