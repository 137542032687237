@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&display=swap");

.a {
  flex: 0 0 auto;
}
.b {
  flex: 1 1 auto;
  /* height: calc(100% - 460px); */
  display: block;
  flex-direction: column;
}

.page-title {
  font-family: "Playfair Display", serif;
  font-size: 1.9em;
  font-style: italic;
  color: black;
}
.sub-title {
  font-family: Oswald, sans-serif;
  font-weight: 500;
  color: #242423;
  font-size: 1em;
  letter-spacing: 1px;
  margin: 5px 0;
}
.instruction {
  font-family: Oswald, sans-serif;
  font-weight: 300;
  color: #242423;
  font-size: 1em;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.location-txt-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

.location-txt {
  text-align: right;
}

.left-col {
  padding: 1.2em;
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.scroll {
  overflow: scroll;
}

@media (max-width: 460px) {
  .page-title {
    font-size: 1.25em;
    margin-top: 10px;
  }
  .sub-title {
    font-weight: 400;
    color: #242423;
    font-size: 0.9em;
    letter-spacing: 1px;
    margin-top: 15px;
  }
  .left-col {
    padding: 0 12px 0;
    border-right: none;
  }

  .location-txt-row {
    margin-top: 0;
  }
  .a {
    flex: 0 0 auto;
  }
}

.view-btn {
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
}

.no-pad {
  padding: 0;
}

.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
}

.no-results-text {
  text-align: center;
  font-size: 1.25em;
  font-family: Oswald, sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  background-color: #775328;
  color: transparent;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  padding: 3em;
}

.select-location-modal {
  position: absolute;
  background-color: #ffffffe8;
  left: 50%;
  top: 35%;
  min-width: 250px;
  transform: translate(-50%, -50%);
  z-index: 999;
  text-align: center;
  align-self: center;
  padding: 30px 10px 30px 10px;
  border: 1px solid #00000044;
}

.modal-title {
  color: white;
  font-family: Oswald;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 1.75px;
  margin-top: 15px;
}

.modal-message {
  color: #242423;
  font-family: Oswald;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.25px;
  margin: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  max-width: 30px;
  cursor: pointer;
  filter: drop-shadow(0 0 30px #000);
  z-index: 2;
}

.button {
  height: 40px;
  margin-top: 10px;
  min-width: 60px;
  color: #ffffff;
  font-family: Oswald;
  font-size: 18px;
  letter-spacing: 1.25px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
}
