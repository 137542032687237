.logo-svg {
  fill: #fff;
  width: 240px;
  padding: 10px 5px;
}

.nav-links {
  padding: 0;
}

.nav-links a {
  color: white;
}

.upper-links {
  padding-right: 10px;
  padding-left: 10px;
}

.visibledevice {
  display: none;
}

.visibledesktop {
  display: display;
}

@media (max-width: 767px) {
  .visibledevice {
    display: block;
  }
  .visibledesktop {
    display: none;
  }
}

#collapsedtabs a {
  color: white;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 5px auto var(--member-site-gold) !important;
  box-shadow: 10px white;
}

#exit-popup .modal-content {
  background-color: rgba(255, 255, 255, 1.0) !important;
}