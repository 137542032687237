.form-control[data-v-871ecb12] {
  background-color: #212529 !important;
  color: #fff !important;
}
.form-check-label[data-v-871ecb12],
.form-label[data-v-871ecb12] {
  color: #fff;
}
.btn-secondary[data-v-871ecb12] {
  font-size: 12px;
  padding-top: 0.5px;
  padding-bottom: 0.5px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 4px;
}
.member-signature .modal-content[data-v-871ecb12] {
  background-color: #000;
  color: #fff;
}
h2.log-title[data-v-871ecb12] {
  font-size: 1.5rem;
  font-family: "Playfair Display", sans-serif;
    font-style: italic;
    color: #fff;
    padding: 0 0 0 10px;
}