@import url("https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap");

.category-container {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 0;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(134, 132, 128, 0.15);
}

.category-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1500px;
}

.category {
  margin-right: 25px;
  font-family: var(--menu-item-banner-font);
  font-weight: 400;
  letter-spacing: var(--legacy-125-letter-spacing);
  font-size: 16px;
  color: #000;
  margin-bottom: 12px;
  border-bottom: 2px solid transparent;
  text-transform: capitalize;
}

.category:hover {
  text-decoration: none;
}

.category-item {
}

.category-selected {
  border-bottom: 2px solid var(--link-color);
}
