@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap");

.wci-item-wrap {
  box-sizing: border-box;
  height: 250px;
  width: 100%;
  border: 1px solid rgba(134,132,128,0.15);
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 0 4px 0 rgba(222,219,214,0.15);
  margin-top: 10px;
  margin-left: 0;
  padding:0;
}

.wci-wrap {
  border: 1px solid #DEDBD6;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 0 4px 0 rgba(134,132,128,0.15);
  margin: 15px 0 30px 0;
}

.wci-image-col {
  padding: 0;
  width: 100px;
  justify-content: center;
  align-items: center;
  background-color:'green';
  align-self: center;
}

.wci-item-name {
  margin: 10px;
  color: #242423;
  font-family: Oswald;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 25px;
  text-align: left;
}

.wci-use-btn {
  box-sizing: border-box;
  height: 58px;
  border-radius: 3px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content:center;
  background-color: var(--member-site-gold);
  font-family: Oswald;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.25px;
  text-align: center;
  margin:50px 0 20 0px;
  cursor: pointer;
}

.wci-cost {
  margin-top:25px;
  margin-right: 15px;
  font-family: Oswald;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


@media (max-width: 991px) {
  .wci-cost {
    margin: 20px;
    flex-direction: row;
    justify-content: space-around;
  }
}

.cost-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.per-bottle {
  font-family: Oswald;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  letter-spacing: initial;
}

.wci-img {
  width:100%;
  max-width: 880px;
}

.wci-item-description {
  margin: 10px;
  color: #242423;
  font-family: Oswald;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.25px;
  line-height: 25px;
  text-align: left;
}

.strikethru {
  text-decoration-line: line-through;
  font-weight: normal;
  color: gray;
}

.dropdown-container .dropdown-cc {
  margin-right: 5px;
  padding: 0px;
  margin-bottom: 50px;
}

.dropdown-container .dropdown-cc .caret{
  right: 5%;
}

.dropdown-container input {
  text-align: center;
}

.dropdown-container .select-content {
  height: 130px;
}

.dropdown-container .item {
  padding: 7px 15px;
}