
  .tinted-container {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.6) 50%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
  
  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 85vh;
    background-color: #00000022;
  }

  .create-password-button {
    background-color: var(--member-site-gold);
    transition: all 0.2s ease 0s;
    border-radius: 9px;
    text-align: center;
    display: flex;
  width: 261px;
  height: 54px;
  padding: 15px 28px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  }
  
  .create-password-button:hover {
    background-color: #99825D;
  }
  
  .create-password-text {
    color: #FFF;
  text-align: center;
  font-family: Libre Franklin, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1px;
  }

  @media (max-width: 767px) {
    .create-password-button {
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    .create-password-button {
      width: 261px;
    }
  }

  @media (max-width: 425px) {
    .create-password-button {
      width: 100%;
    }
  }
  
  .userSwitch {
    margin-top: 7em;
    margin-right: 4em;
    height: 15em;
    width: 10em;
    background-color: #000000dd;
    border-radius: 5px;
    border: 1px solid #ffffff66;
    padding: 1em;
    box-shadow: 0px 0px 50px #000;
    overflow: scroll;
    cursor: pointer;
  }