@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap");
.span {
  display: flex;
  flex-direction: row;
  height: 30px;
  margin-right: 18px;
  width: 140px;
}

.plus-minus {
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 0 12px !important;
}

.qty-num {
  color: #242423;
  font-family: var(--title-font-family);
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 25px;
  width: 100%;
  text-align: center;
}

.qty-not-available {
  color: #242423;
  font-family: var(--title-font-family);
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.25px;
  line-height: 25px;
  width: 100%;
  text-align: center;
}
