.jumbo {
  background-image: url("../../assets/images/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 0;
  margin-bottom: 0;
}

.bg-light {
  margin-top: 6em;
  color: white;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.bg-white {
  margin-top: 6em;
  color: white;
  background-color: #FFFFFF;
  border-bottom-left-radius: 47px;
}

h2.log-title {
  border-bottom: solid 1px var(--member-site-gold);
  font-family: "Playfair Display", serif;
  font-style: italic;
}

.login-error {
  color: red;
  background-color: pink;
  font-size: 14px;
  padding: 10px;
}
