.jumbo {
  background-image: url("../../assets/images/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 0;
  margin-bottom: 0;
}

.tinted-container {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 85vh;
  background-color: #00000022;
}

.userSwitch {
  margin-top: 7em;
  margin-right: 4em;
  height: 15em;
  width: 10em;
  background-color: #000000dd;
  border-radius: 5px;
  border: 1px solid #ffffff66;
  padding: 1em;
  box-shadow: 0px 0px 50px #000;
  overflow: scroll;
  cursor: pointer;
}

.desc {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
}

.primary-btn {
  background-color: var(--member-site-gold);
  color: white;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  width: 100%;
  padding: 15px;
  margin: 5px;
}

.secondary-btn {
  background-color: #000 !important;
  color: var(--member-site-gold);
  font-weight: 500;
  border-color: var(--member-site-gold);
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
  width: 100%;
  padding: 15px;
  margin: 5px;
}
