.input-field[data-v-74af18d8] {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  cursor: pointer;
}
.input-field.one-half[data-v-74af18d8] {
  width: 50%;
}
.input-field.one-third[data-v-74af18d8] {
  width: 33%;
}
.input-field input[data-v-74af18d8] {
  border: 2px solid #dedbd6;
  border-radius: 3px;
  padding: 15px;
  font-family: "Oswald";
  font-weight: 200;
  color: black;
  cursor: pointer;
}
@media (max-width: 460px) {
.input-field input[data-v-74af18d8] {
    padding: 10px;
    font-size: 15px;
    margin: 2px;
}
}
.select-content[data-v-74af18d8] {
  position: absolute;
  top: 60px;
  width: 100%;
  overflow: auto;
  border-right: 2px solid #dedbd6;
  border-left: 2px solid #dedbd6;
  border-bottom: 2px solid #dedbd6;
  z-index: 99;
  display: none;
}
.select-content.open[data-v-74af18d8] {
  display: block;
}
.select-content .item[data-v-74af18d8] {
  background: white;
  padding: 10px 15px;
  border-bottom: 1px solid #dedbd6;
  font-family: Oswald;
  font-weight: 300;
  cursor: pointer;
}
.select-content .item.selected[data-v-74af18d8] {
  color: black;
  font-weight: 400;
}
.select-content .item[data-v-74af18d8]:hover {
  font-weight: 400;
}
.button[data-v-74af18d8] {
  height: 60px;
  margin: 30px 0 0;
  color: #ffffff;
  font-family: Oswald;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  width:100%;
}
