.logo-svg-light {
  fill: black;
  width: 170px;
  height: 63.21px;
}

.nav-links-light {
  padding: 0;
}

.nav-links-light a {
  color: black !important;
}

.upper-links {
  padding-right: 10px;
  padding-left: 10px;
}

.visibledevice {
  display: none;
}

.visibledesktop {
  display: display;
}

@media (max-width: 767px) {
  .visibledevice {
    display: block;
  }
  .visibledesktop {
    display: none;
  }
  
  .logo-svg-light {
    width: 160px;
    height: 55.28px;
  }
}

#collapsedtabs a {
  color: white;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 5px auto var(--member-site-gold) !important;
  box-shadow: 10px white;
}

#exit-popup .modal-content {
  background-color: rgba(255, 255, 255, 1.0) !important;
}