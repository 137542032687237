.footer[data-v-55c26245] {
  display: flex;
  flex-direction: row;
  background-color: #dedbd6;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.footer-item[data-v-55c26245] {
  color: black;
  font-family: Oswald;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.25px;
  margin: 0 30px;
}
.version[data-v-55c26245] {
  position: absolute;
  font-size: x-small;
  right: 25px;
}
@media (max-width: 460px) {
.footer-item[data-v-55c26245] {
    font-weight: 300;
    font-size: 14px;
    margin: 0 5px;
}
.footer[data-v-55c26245] {
    justify-content: space-around;
    padding: 8px 15px;
}
}
@media (max-width: 375px) {
.footer-item[data-v-55c26245] {
    font-size: 12px;
}
.footer[data-v-55c26245] {
    padding: 8px 12px;
}
}
