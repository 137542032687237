.wrapper {
  width: 100%;
  height: 100%;
}

.icon {
  max-width: 100px;
}

.marker {
    width: 48px;
    height: 48px;
}