@import url("https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap");

.selector-container {
  display: inline-flex;
  cursor: pointer;
  background-color: white;
  border-radius: 3px;
  box-shadow: 1px 1px 1px 1px rgba(150, 150, 150, 0.1),
    1px 1px 1px 1px rgba(250, 250, 250, 0.1);
  margin: 0 0 1.5em 0;
  border: 1px solid #eee;
  width: 100%;
  justify-content: space-around;
}

@media (max-width: 460px) {
  .selector-container {
    margin: 0 0 5px 0;
    width: 100%;
    justify-content: space-around;
  }
}
.selector-container.small {
  margin-bottom: 1em;
}
.segmentLabel {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: Oswald, sans-serif;
  font-weight: 900;
  color: #6f6c67;
  letter-spacing: 1px;
  font-size: 1em;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.segment {
  padding: 0.7em 1.6em;
  list-style: none;
  margin: 4px;
  border-radius: 3px;
  width: 30%;
}
.selected {
  color: white;
}

.segment-selected {
  background-color: var(--link-color);
}

.segment.large {
  padding: 1em 2.6em;
}

@media (max-width: 570px) {
  .segment.large {
    padding: 0.7em 0.9em;
  }
}

@media (max-width: 1450px) {
  .segment {
    padding: 0.7em 0.6em;
    margin: 3px;
  }
}
