.br-logo {
  max-width: 9em;
  margin: auto;
}

.inputs {
  height: 3em;
}

.minus-button {
  width: 3em;
}

.plus-button {
  width: 5em;
}

.innner-box {
  padding: 1em;
  margin-top: 1em;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.thisSpinner {
  margin: 1em 0 0 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
