.alert-error-body {
  background-color: white;
  color: gray;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.alert-error-header {
  background-color: white;
  align-items: center;
}

.alert-error-header-x {
  background-color: white;
  align-items: center;
}
.alert-error-header-x button, .alert-error-header-x button:hover{
  color: black;
  font-size: 2rem;
}

.alert-error-title {
  color: black !important;
  margin-top: 10px;
}
.alert-error-header .close {
}


.olo-body-small {
  font-size: 18px;
}

@media (max-width: 375px) {
  .olo-body-small {
    font-size: 16px;
  }  
}
