.inner-wrap[data-v-ebea81c7] {
  background-color: white;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(134, 132, 128, 0.15);
}
.big-wrap[data-v-ebea81c7] {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  background-color: #f6f5f2;
  overflow: hidden;
  height: 100%;
}
.little-wrap[data-v-ebea81c7] {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  flex-basis: initial;
  height: 100%;
  overflow: auto;
}
@media (max-width: 445px) {
.little-wrap[data-v-ebea81c7] {
    padding-left: 0px;
    padding-right: 0px;
}
}
@media (min-width: 768px){
.button[data-v-ebea81c7] {
    height: 60px;
    margin: 15px 0;
    color: #ffffff;
    font-family: Oswald;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.25px;
    line-height: 25px;
    text-align: center;
    cursor: pointer;
}
}
@media (max-width: 767px){
.button[data-v-ebea81c7] {
    color: #ffffff;
    margin: 0;
    font-family: Oswald;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.25px;
    line-height: 25px;
    text-align: center;
    cursor: pointer;
}
}
.btn[data-v-ebea81c7]:focus,
.btn[data-v-ebea81c7]:active {
  box-shadow: none !important;
}
.inverted[data-v-ebea81c7] {
  background-color: transparent !important;
  color: var(--link-color) !important;
  border: 1px solid var(--link-color) !important;
}
.plain[data-v-ebea81c7] {
  background-color: transparent !important;
  color: var(--link-color) !important;
  text-decoration: underline;
  border: none;
}
@media (min-width: 768px){
.lefty[data-v-ebea81c7] {
  text-align: left;
  width: 300px;
  margin-right: 10px;
}
}
@media (max-width: 767px) {
.lefty[data-v-ebea81c7] {
    text-align: left;
    width: 230px;
}
}
@media (min-width: 768px){
.checkout[data-v-ebea81c7] {
  color: #242423;
  font-family: var(--category-font-family);
  font-size: 28px;
  font-style: italic;
  letter-spacing: 0.75px;
  margin-bottom: 15px;
  border-bottom: 0px;
  font-weight: 400;
  line-height: 1.5;
  padding-left: 0px;
}
}
@media (max-width: 767px){
.checkout[data-v-ebea81c7] {
    color: #242423;
    font-family: var(--category-font-family);
    font-size: 26px;
    font-style: italic;
    letter-spacing: 0.75px;
    margin-bottom: 5px;
    border-bottom: 0px;
    font-weight: 400;
    line-height: 1.5;
    padding-left: 0px;
}
}
.back-to-my-bag[data-v-ebea81c7] {
  color: var(--link-color);
  align-self: flex-start;
  margin: 0 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-height: 800px) {
.back-to-my-bag[data-v-ebea81c7] {
    margin: 0 3vh;
}
}
@media (min-width: 768px){
.back-to-my-bag-text[data-v-ebea81c7] {
    color: var(--link-color);
    font-family: var(--title-font-family);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px;
    margin: 3vh 0vh 3vh 1vh;
    text-decoration: underline;
    cursor: pointer;
}
}
@media (max-width: 767px){
.back-to-my-bag-text[data-v-ebea81c7] {
    color: var(--link-color);
    font-family: var(--title-font-family);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px;
    margin: 1vh 0vh 1vh 1vh;
    text-decoration: underline;
    cursor: pointer;
}
}
.icon-back[data-v-ebea81c7] {
  height: 20px;
  width: 20px;
  margin: 1px 5px 0 0;
}
.olo-input[data-v-ebea81c7] {
  box-sizing: border-box;
  height: 63px;
  border: 1px solid #868480;
  border-radius: 3px;
  background-color: #ffffff;
  width: 100%;
  margin: 12px 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: relative;
}
.horizontal-edges[data-v-ebea81c7] {
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 20px;
}
input[data-v-ebea81c7] {
  border: none;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  background-color: #ffffff;
}
input[data-v-ebea81c7]::-webkit-credentials-auto-fill-button {
  position: absolute;
  right: 3px;
}
.divider[data-v-ebea81c7] {
  height: 2px;
  width: calc(100% + 60px);
  margin-left: -30px;
  background-color: #dedbd6;
}
.pad[data-v-ebea81c7] {
  padding: 0 44px;
}
.eye[data-v-ebea81c7] {
  height: 12px;
  width: 16px;
  margin: 24px 25px;
}
.eye-wrap[data-v-ebea81c7] {
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (max-width: 480px) {
.eye-wrap[data-v-ebea81c7] {
    right: 15px;
}
}
.dont-have[data-v-ebea81c7] {
  color: #242423;
  font-family: Oswald;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: var(--legacy-125-letter-spacing);
  line-height: 25px;
  cursor: pointer;
}
.checkout-as-guest[data-v-ebea81c7] {
  color: var(--link-color);
  font-family: var(--title-font-family);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 25px;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}
.instruction-container[data-v-ebea81c7] {
  color: #242423;
  font-family: Oswald !important;
  font-size: 16px !important;
  letter-spacing: 1.11px !important;
  line-height: 21px !important;
  align-self: flex-start;
  font-style: italic;
  font-weight: 200;
}
.instruction-container-checkbox[data-v-ebea81c7] {
  color: #242423;
  font-family: Oswald !important;
  font-size: 16px !important;
  letter-spacing: 1.11px !important;
  line-height: 21px !important;
  max-width: 100%;
  align-self: flex-start;
  font-weight: 200;
  flex-direction: column;
  display: flex;
}
@media (max-width: 482px) {
.instruction-container[data-v-ebea81c7] {
    max-width: 100%;
}
.password-info .tooltipPassword[data-v-ebea81c7]:hover {
    height: 62px;
}
.divider[data-v-ebea81c7] {
    width: calc(100% + 30px);
    margin-left: -15px;
}
}
.align-top[data-v-ebea81c7] {
  align-self: flex-start;
  margin-top: -3px;
}
.info-icon[data-v-ebea81c7] {
  height: 18px;
  width: 18px;
  margin: 3px 0 0 10px;
}
.validation-message[data-v-ebea81c7] {
  color: #e13d3dbb;
  font-size: 0.9rem;
}
.password-match[data-v-ebea81c7] {
  margin-top: -20px;
  margin-bottom: 10px;
}
.password[data-v-ebea81c7] {
  margin: 1px;
}
.footerCheckout[data-v-ebea81c7] {
  text-decoration: underline;
}
.custom-control-label[data-v-ebea81c7] {
  color: #242423 !important;
}
.custom-control-input:checked ~ .custom-control-label[data-v-ebea81c7]::before {
  color: #fff;
  border-color: var(--link-color);
  background-color: var(--link-color);
}
.custom-control-input-agreed:checked ~ .custom-control-label[data-v-ebea81c7]::before {
  color: #fff;
  border-color: var(--link-color);
  background-color: var(--link-color);
}
.password-info[data-v-ebea81c7] {
  margin-top: -2px;
  margin-bottom: 3px;
}
.tooltipPassword[data-v-ebea81c7] {
  position: relative;
  display: inline-block;
  width: 375px;
  margin-bottom: 14px;
  margin-top: 8px;
}
.tooltipPassword .tooltipPasswordText[data-v-ebea81c7] {
  /* visibility: hidden; */
  word-wrap: normal;
  padding: 3px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 10%;
  color: #242423 !important;
}
.tooltipPassword:hover .tooltipPasswordText[data-v-ebea81c7] {
  visibility: visible;
}