tr {
  border-top: 1px solid;
}

thead {
  border-bottom: 2px solid #404040;
}

.card-text {
  color: #ccc;
  font-size: 16px;
}

.visibledevice {
  display: none;
}
.visibledesktop {
  display: display;
}

@media (max-width: 767px) {
  .visibledevice {
    display: block;
  }
  .visibledesktop {
    display: none;
  }
}

.jumbo {
  background-image: url('../../assets/images/bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 0;
  margin-bottom: 0;
}

.tinted-container {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.float-right {
  float: right;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 85vh;
  background-color: #00000022;
}

.userSwitch {
  margin-top: 7em;
  margin-right: 4em;
  height: 15em;
  width: 10em;
  background-color: #000000dd;
  border-radius: 5px;
  border: 1px solid #ffffff66;
  padding: 1em;
  box-shadow: 0px 0px 50px #000;
  overflow: scroll;
  cursor: pointer;
}
